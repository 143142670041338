import React, { useEffect, useState } from 'react'
import { APP_DATA } from '../utils';
import { ethers } from 'ethers';

export default function Registration() {
  const [input, setInput] = useState({
    email: '',
    sponsor: ''
  });

  const checkUserExists = async () => {
    const provider = new ethers.BrowserProvider(window.ethereum);
    await window.ethereum.request({ method: "eth_chainId" })
    const signer = await provider.getSigner();
    const address = await signer.getAddress();
    const smart_contract_main = new ethers.Contract(APP_DATA.SC_ADDRESS, APP_DATA.SC_ABI, signer);
    const res = await smart_contract_main.doesUserExist(address);
    if (res) {
      window.location.href = "/swap";
    }
  };

  useEffect(() => {
    checkUserExists();
  }, []);

  const register = async () => {
    const provider = new ethers.BrowserProvider(window.ethereum);
    await window.ethereum.request({ method: "eth_chainId" })
    const signer = await provider.getSigner();
    const address = await signer.getAddress();
    const smart_contract_main = new ethers.Contract(APP_DATA.SC_ADDRESS, APP_DATA.SC_ABI, signer);
    const trans = await smart_contract_main.registration(input.email, input.sponsor, { from: address });
    const dTrans = await trans.wait();
    window.location.reload();
  };

  return (
    <div className="app-layout">
      <div className="app-decorators">
        <div className="position-absolute deco-square"><img src="./assets/images/layout-blue-square.svg" alt="" /></div>
        <div className="rounded-circle position-absolute gradient-blue" />
        <div className="rounded-circle position-absolute gradient-yellow" />
        <div className="position-absolute deco-dots"><img src="./assets/images/layout-dots.svg" alt="" /></div>
      </div>
      <main className="app-content">
        <div className="app-header app-header--loggedout">
          <div className="container">
            <section className="app-header__inner">
              <div className="logo">
                <img src="./assets/images/Alarmz-logo.svg" alt="Alarmz logo" />
              </div>
            </section>
          </div>
        </div>
        <div className="container">
          <section className="layout__content layout__content--registration">
            <div className="illustration illustration--registration">
              <img src="./assets/images/registration-illustration.svg" alt="" />
            </div>
            <div className="position-relative block-details">
              {/* Decorator */}
              <div className="position-absolute star">
                <img src="./assets/images/stars-contactUs.svg" alt="..." />
              </div>
              <div className="d-inline-block position-absolute flower">
                <img src="./assets/images/flower-contactUs.svg" alt="..." />
              </div>
              <div className="bg-white block-details__inner">
                <img src="./assets/images/registration-semi-round.svg" alt="Become Alarmz Member" className="semi-circle-title" />
                <h3 className="section-title h3">
                  Register Yourself <span className="color-orange">!</span>
                </h3>
                <p className="text-center text-subtitle text-subtitle--register">
                  Lorem ipsum dolor sit amet consectetur. Id euismod quam
                </p>
                <form action className="w-100 auth-form">
                  <div className="form-group">
                    <input type="email" value={input.email} onChange={(e) => setInput({ ...input, email: e.target.value })} placeholder="Your Email Address..." className="form-control" />
                  </div>
                  <div className="form-group">
                    <input type="email" value={input.sponsor} onChange={(e) => setInput({ ...input, sponsor: e.target.value })} placeholder="Sponsor Email Address..." className="form-control" />
                  </div>
                </form>
                <a onClick={register} className="button button-yellow launch-btn">
                  {/* <img
                    src="./assets/images/animating/rocket/rocket.svg"
                    alt="launch"
                  /> */}
                  <span className="rounded-circle bg-white icon">
                    <img src="./assets/images/icons/rocket.svg" alt="" />
                  </span>
                  <span className="text-center text">Let's Connect!</span>
                </a>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
}
