import React, { useEffect, useState } from "react";
import { ethers, formatEther } from "ethers";
import { APP_DATA } from "../utils";
import cloneDeep from 'lodash/cloneDeep';

const SponsorTree = () => {
  const dataTemplate = {
    'address': '',
    'name': 'Email Address',
    'title': '',
    'earned': '',
    'className': '',
    'children': []
  };

  const [tree, setTree] = useState();

  const getSponsorTree = async () => {
    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const address = await signer.getAddress();
      const smartContract = new ethers.Contract(APP_DATA.SC_ADDRESS, APP_DATA.SC_ABI, provider);

      const addDownLine = async (adr, childArray, color) => {
        const downLine = await smartContract.getdownline(adr);
        for (let i = 0; i < downLine.length; i++) {
          const temp = cloneDeep(dataTemplate);
          const earning = await smartContract.earningFromDowlineUser(downLine[i].myAddress, address);
          childArray.push({ ...temp, 'title': downLine[i].email, 'address': downLine[i].myAddress, 'earned': formatEther(earning), 'className': color });
        };
      };

      const userMaping = await smartContract.UserMaping(address);

      let tempTree = { ...cloneDeep(dataTemplate), 'title': userMaping.email, 'className': 'blue' };

      await addDownLine(address, tempTree.children, 'orange');

      for (let i = 0; i < tempTree.children.length; i++) {
        await addDownLine(tempTree.children[i].address, tempTree.children[i].children, 'yellow');

        for (let j = 0; j < tempTree.children[i].children.length; j++) {
          await addDownLine(tempTree.children[i].children[j].address, tempTree.children[i].children[j].children, 'green');
        }
      }
      setTree(tempTree);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSponsorTree();
  }, []);

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = `http://${window.location.host}/assets/scripts/jquery.orgchart.min.js`;
    script1.async = true;
    document.body.appendChild(script1);

    const ds = JSON.stringify(tree, function replacer(key, value) { return value })
    window.sessionStorage.setItem('sponsorTree', ds);

    const script2 = document.createElement('script');
    script2.src = `http://${window.location.host}/assets/scripts/org-chart.js`;
    script2.async = true;
    document.body.appendChild(script2);

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    }
  }, [tree]);

  return (<></>);
};

export default SponsorTree;