import React, { useEffect, useState } from "react";
import { ethers, formatEther } from "ethers";
import { APP_DATA } from "../utils";
import { useMatch, useNavigate } from "react-router-dom";
import SponsorTree from "../components/sponsorTree";

const ReferAndEarn = () => {
  const navigate = useNavigate();

  const [userMaping, setUserMaping] = useState({
    email: '',
    sponsor_address: '',
    second_level: '',
    third_level: '',
    time: '',
    myAddress: '',
    amountofPurchase: '',
    downline: '',
    myearning: 0
  });

  const [sponsorMail, setSponsorMail] = useState('');
  const myEarning = formatEther(userMaping.myearning);

  const getUserData = async () => {
    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const address = await signer.getAddress();
      const smartContract = new ethers.Contract(APP_DATA.SC_ADDRESS, APP_DATA.SC_ABI, signer);
      const userExist = await smartContract.doesUserExist(address);
      if (userExist) {
        const temp = await smartContract.UserMaping(address);
        setUserMaping(temp);
        const temp2 = await smartContract.UserMaping(temp.sponsor_address);
        setSponsorMail(temp2.email);
      } else {
        navigate('/app');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const copyToClipBoard = copyMe => {
    navigator.clipboard.writeText(copyMe);
  };

  return (
    <div className="app-layout">
      <div className="app-decorators">
        <div className="position-absolute deco-square"><img src="./assets/images/layout-blue-square.svg" alt="" /></div>
        <div className="rounded-circle position-absolute gradient-blue" />
        <div className="rounded-circle position-absolute gradient-yellow" />
        <div className="position-absolute deco-dots"><img src="./assets/images/layout-dots.svg" alt="" /></div>
      </div>
      {/* Page content starts */}
      <main className="app-content">
        <div className="app-header">
          <div className="container">
            <section className="app-header__inner">
              <div className="logo">
                <img src="./assets/images/Alarmz-logo.svg" alt="Alarmz logo" />
              </div>
              <div className="right-menu">
                <a href="./swap" className="menu-link">
                  <div className="rounded-circle circle circle--yellow">
                    <img src="./assets/images/icons/icon-double-arrows.svg" alt=".." />
                  </div>
                  <span className="text-uppercase fw700">SWAP IT!</span>
                </a>
                <a href="./referandearn" className="menu-link">
                  <div className="rounded-circle circle circle--pink">
                    <img src="./assets/images/icons/icon-dollar.svg" alt=".." />
                  </div>
                  <span className="text-uppercase fw700">REFER AND EARN</span>
                </a>
                <div className="user-info">
                  <div className="rounded-circle dp-cover">
                    <img src="./assets/images/pixelated-dp.png" alt="User DP" />
                  </div>
                  <div className="info">
                    <div className="email">{userMaping.email}</div>
                    <div className="email-sponsor">
                      <strong className="fw600">Sponsor Address:</strong>
                      <span>{sponsorMail}</span>
                      <button
                        onClick={() => copyToClipBoard(sponsorMail)}
                        className="rounded-circle border-0 button button-yellow"
                      >
                        <img src="./assets/images/icons/icon-copy.svg" alt="Copy icon" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-none d-lg-inline-flex">
                  <a href="./app" className="logout" title="Logout">
                    <img src="./assets/images/icons/icon-logout.svg" alt="..." />
                  </a>
                </div>
              </div>
              <div className="d-inline-flex d-lg-none align-items-center">
                <button className="btn border-0 p-1 app-menu-toggle" type="button">
                  <img src="./assets/images/icons/icon-menu-dots.svg" alt="" />
                </button>
                <div>
                  <a href="./connect-wallet.html" className="logout ms-3" title="Logout">
                    <img src="./assets/images/icons/icon-logout.svg" alt="..." />
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
        <section className="profile-referral">
          <div className="deco-blue" />
          <div className="deco-yellow" />
          <div className="container">
            <div className="profile-referral__top">
              <div className="deco-triangle"><img src="./assets/images/profile-referral/deco-triangle.png" alt="deorative" />
              </div>
              <div className="deco-sq"><img src="./assets/images/profile-referral/deco-square.svg" alt="deorative" /></div>
              <div className="profile-referral__top-inner">
                <div className="column-1">
                  <img src="./assets/images/profile-referral/userwithtitle.svg" alt="title" />
                </div>
                <div className="column-2">
                  <div className="profile-referral-email bg-blue">
                    <div className="profile-referral-email__label">Email Address</div>
                    <div className="profile-referral-email__value">
                      {userMaping.email}
                    </div>
                    <a
                      onClick={() => copyToClipBoard(userMaping.email)}
                      className="button-copy"
                      style={{ cursor: 'pointer' }}
                    >
                      <img src="./assets/images/icons/icon-copy.svg" alt="Copy icon" />
                    </a>
                  </div>
                  <div className="profile-referral-email bg-yellow">
                    <div className="profile-referral-email__label">Wallet Address</div>
                    <div className="profile-referral-email__value">
                      {userMaping.myAddress}
                    </div>
                    <a
                      onClick={() => copyToClipBoard(userMaping.myAddress)}
                      className="button-copy"
                      style={{ cursor: 'pointer' }}
                    >
                      <img src="./assets/images/icons/icon-copy.svg" alt="Copy icon" />
                    </a>
                  </div>
                  <div className="profile-referral-email bg-green">
                    <div className="profile-referral-email__label">Sponsor Address</div>
                    <div className="profile-referral-email__value">
                      {sponsorMail}
                    </div>
                    <a
                      onClick={() => copyToClipBoard(sponsorMail)}
                      className="button-copy"
                      style={{ cursor: 'pointer' }}
                    >
                      <img src="./assets/images/icons/icon-copy.svg" alt="Copy icon" />
                    </a>
                  </div>
                </div>
                <div className="column-3">
                  <div className="profile-referral-total">
                    <div className="txt1">Total Earning</div>
                    <div className="txt2">{myEarning} AMZ= ${myEarning / 10} USD</div>
                    <div className="txt3">Withdraw Fee applied!</div>
                    <a href className="button button-yellow">Want to withdraw?</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid mt-5">
            <div className="chart-container" />
          </div>
        </section>
      </main>
      <SponsorTree />
    </div>
  );
};

export default ReferAndEarn;