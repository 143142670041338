import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { ErrorBoundary } from 'react-error-boundary';

const root = ReactDOM.createRoot(document.getElementById('root'));
const logError = (error) => console.log(error);

root.render(
  <ErrorBoundary onError={logError}>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>
);