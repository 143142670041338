import React, { useEffect, useState } from 'react'
import { ethers, formatEther } from 'ethers';
import { APP_DATA, formatWalletAddr } from "../utils";
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useWeb3Modal } from '@web3modal/react';
import { useAccount, useWalletClient } from 'wagmi';
import { getContract, waitForTransaction } from '@wagmi/core';

export default function SwapComponent({ name }) {
  const [active, setActive] = useState({
    name: "USDT",
    imageURL: "/assets/images/icons/usdt.png",
    price: 0
  });
  const [amount, setAmount] = useState('');
  const [amountSwapTo, setAmountSwapTo] = useState(0);

  const queryReferralId = useSelector(state => state.main.queryReferralId);
  const backendReferralId = useSelector(state => state.main.backendReferralId);
  const localReferralId = useSelector(state => state.main.localReferralId);

  useEffect(() => {
    onChangeSwapFrom("USDT", "/assets/images/icons/usdt.png");
  }, []);

  const [isChecked, setIsChecked] = useState(false);

  const [estimatedALZ, setEstimatedALZ] = useState();

  const { open } = useWeb3Modal()
  const { address, isConnected } = useAccount();
  const { data: walletClient } = useWalletClient()

  const swap = async () => {
    if (isChecked) {
      if (address !== queryReferralId && address !== localReferralId) {
        if (await isSponsorAvailable()) {
          if (amount <= 50000) {
            !isConnected && open();
            if (isConnected) {
              try {
                // const provider = new ethers.BrowserProvider(window.ethereum);
                // await window.ethereum.request({ method: "eth_chainId" })
                // const signer = await provider.getSigner();
                // const address = await signer.getAddress();

                const smart_contract_main = getContract({
                  address: APP_DATA.SC_ADDRESS,
                  abi: APP_DATA.SC_ABI,
                  walletClient,
                });
                const smart_contract_usdt = getContract({
                  address: APP_DATA.SC_ADDRESS_USDT,
                  abi: APP_DATA.SC_USDT_ABI,
                  walletClient,
                });
                console.log(smart_contract_main, smart_contract_usdt)

                // const smart_contract_main = new ethers.Contract(APP_DATA.SC_ADDRESS, APP_DATA.SC_ABI, signer);
                // const smart_contract_usdt = new ethers.Contract(APP_DATA.SC_ADDRESS_USDT, APP_DATA.SC_USDT_ABI, signer);

                // const smart_contract_main = new ethers.Contract(APP_DATA.SC_ADDRESS, APP_DATA.SC_ABI, walletClient);
                // const smart_contract_usdt = new ethers.Contract(APP_DATA.SC_ADDRESS_USDT, APP_DATA.SC_USDT_ABI, walletClient);
                // const allowance = await smart_contract_usdt.allowance(address, APP_DATA.SC_ADDRESS);
                const allowance = await smart_contract_usdt.read.allowance([address, APP_DATA.SC_ADDRESS]);
                console.log(allowance);
                const mAmount = `1` + '0'.repeat(71);
                // console.log(formatEther(allowance1))

                if (allowance != undefined) {
                  if (Number(allowance) < parseInt(amount)) {
                    // const res = await smart_contract_usdt.approve(APP_DATA.SC_ADDRESS, mAmount);
                    console.log("0");
                    const res = await smart_contract_usdt.write.approve([APP_DATA.SC_ADDRESS, mAmount]);
                    // await res.wait();
                    const wait = await waitForTransaction({
                      hash: res,
                    })
                    console.log(wait)
                    const inp = ethers.parseUnits(amount.toString(), "ether");
                    // console.log('1')
                    // let trans = await smart_contract_main.purchaseTokenALZ(inp.toString(), { from: address });
                    let trans = await smart_contract_main.write.purchaseTokenALZ([inp], { from: address });
                    // console.log('2')
                    console.log(trans)
                    const data = await waitForTransaction({
                      hash: trans,
                    })
                    // console.log('3')
                    console.log(data)
                    // await trans.wait();
                    // const body = referralId
                    //   ? { 'network_name': APP_DATA.network_name, 'wallet_address': address, 'alz_amount': Number(estimatedALZ), 'usdt_amount': amount, 'refer_address': referralId }
                    //   : { 'network_name': APP_DATA.network_name, 'wallet_address': address, 'alz_amount': Number(estimatedALZ), 'usdt_amount': amount };
                    const res2 = await fetch('https://alarmz.io/api2/main/create-transaction', {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({ 'network_name': APP_DATA.network_name, 'wallet_address': address, 'alz_amount': Number(estimatedALZ), 'usdt_amount': amount }),
                    });
                    const data2 = await res2.json();
                    console.log(data2)
                    document.getElementById('thankyouModalPurchase').click();
                    setAmount('');
                    setTimeout(() => {
                      // window.location.reload();
                    }, 3000);
                  } else {
                    const inp = ethers.parseUnits(amount.toString(), "ether");
                    // let trans = await smart_contract_main.purchaseTokenALZ(inp.toString(), { from: address });
                    let trans = await smart_contract_main.write.purchaseTokenALZ([inp], { from: address });
                    console.log(trans)
                    const data = await waitForTransaction({
                      hash: trans,
                    })
                    console.log(data)
                    // await trans.wait();
                    // const body = referralId
                    //   ? { 'network_name': APP_DATA.network_name, 'wallet_address': address, 'alz_amount': Number(estimatedALZ), 'usdt_amount': amount, 'refer_address': referralId }
                    //   : { 'network_name': APP_DATA.network_name, 'wallet_address': address, 'alz_amount': Number(estimatedALZ), 'usdt_amount': amount };
                    const res = await fetch('https://alarmz.io/api2/main/create-transaction', {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({ 'network_name': APP_DATA.network_name, 'wallet_address': address, 'alz_amount': Number(estimatedALZ), 'usdt_amount': amount }),
                    });
                    const data2 = await res.json();
                    console.log(data2)
                    document.getElementById('thankyoupurchase').click();
                    setAmount('');
                    setTimeout(() => {
                      // window.location.reload();
                    }, 3000);
                  }
                } else {
                  console.log('alllowance undifined')
                }
              } catch (error) {
                console.log(error);
              }
            } else {
              console.log('not connected')
            }
          }
        } else {
          document.getElementById('sponsorNotAvailable').click();
        }
      } else {
        document.getElementById('sameId').click();
      }
    } else {
      document.getElementById('desclaimer').click();
    }
  };

  const onChangeAmount = async (e) => {
    let { value, min, max } = e.target;
    if (value === '') {
      value = ''
    } else {
      value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    }
    setAmount(value);
  };

  const onChangeSwapFrom = async (data1, data2) => {
    let tempP;
    if (data1 == "BNB") {
      const res = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd");
      tempP = res.data?.binancecoin?.usd;
      setActive({ name: data1, imageURL: data2, price: res.data?.binancecoin?.usd })
    } else if (data1 == "MATIC") {
      const res = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd");
      tempP = res.data["matic-network"]?.usd
      setActive({ name: data1, imageURL: data2, price: res.data["matic-network"]?.usd })
    } else if (data1 == "BTC") {
      const res = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd");
      tempP = res.data?.bitcoin?.usd
      setActive({ name: data1, imageURL: data2, price: res.data?.bitcoin?.usd })
    } else {
      const res = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=tether&vs_currencies=usd");
      tempP = res.data?.tether?.usd
      setActive({ name: data1, imageURL: data2, price: res.data?.tether?.usd })
    }
    const temp = parseFloat(tempP) * 10;
    setAmountSwapTo(temp);
  };

  const getEstimatedALZ = async () => {
    try {
      const provider = new ethers.JsonRpcProvider(APP_DATA.RPC_URL);
      // await window.ethereum.request({ method: "eth_chainId" })
      // const signer = await provider.getSigner();
      const smart_contract_main = new ethers.Contract(APP_DATA.SC_ADDRESS, APP_DATA.SC_ABI, provider);
      const res = await smart_contract_main.getTokensReturn(ethers.parseUnits(amount.toString(), "ether"));
      const temp = res.reduce((partialSum, num) => partialSum + num, 0n);
      setEstimatedALZ(formatEther(temp));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEstimatedALZ();
  }, [amount]);

  const isSponsorAvailable = async () => {
    if (localReferralId) {
      const res = await fetch(`https://alarmz.io/api2/account/is_user?wallet_address=${localReferralId}`);
      const data = await res.json();
      console.log(data);
      return data.user_available;
    }
    return true;
  };

  const selectedLanguage = useSelector(state => state.main.language);

  const getTranslatedText = (textKey) => {
    try {
      const translations = require(`../translations/${selectedLanguage}.json`);
      return translations[textKey] || textKey;
    } catch (error) {
      console.error(`Translation error: ${error}`);
      return textKey;
    }
  };

  return (
    <div className="swapit__inner">
      <div className="deco-circle">
        <img src="./assets/images/deco-circle2.svg" alt="..." />
      </div>
      <div className="deco-rect">
        <img src="./assets/images/deco-rect2.svg" alt="..." />
      </div>
      <div className="rounded-circle position-absolute gradient-yellow-swap" />
      <div className="rounded-circle position-absolute gradient-blue-swap" />
      <div className="swapfromto">
        <div className="swapfromto__left">
          <h3>{getTranslatedText("Swap From")}</h3>
          <div className="alarmz-dropdown">
            <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button">
                <span className="dropdown__selected"><img width="50px" src={active.imageURL} alt="bitcoin" />
                  {active.name}</span>
                <span className="button-circle">
                  <svg width={20} height={24} viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.721652 1.96143C0.190667 1.80953 -0.116642 1.25595 0.0352573 0.724961C0.187158 0.193976 0.740745 -0.113333 1.27173 0.0385669L0.721652 1.96143ZM13.2314 24L7.81235 13.8035L19.3522 14.2088L13.2314 24ZM1.27173 0.0385669C5.87561 1.35561 9.10368 3.12003 11.2327 5.60098C13.3734 8.09545 14.2971 11.19 14.545 14.9391L12.5494 15.0711C12.318 11.573 11.4753 8.95478 9.71496 6.90345C7.943 4.83859 5.13547 3.2241 0.721652 1.96143L1.27173 0.0385669Z" fill="#3A3A3A" />
                  </svg>
                </span>
              </button>
              {/* <ul className="dropdown-menu">
                <li>
                  <a onClick={() => onChangeSwapFrom("USDT", "/assets/images/icons/usdt.png")} className="dropdown-item" role='button'>
                    <img width="50px" src="/assets/images/icons/usdt.png" alt="bitcoin" />
                    USDT
                  </a>
                </li>
                <li>
                  <a onClick={() => onChangeSwapFrom("BNB", "/assets/images/icons/bnb.png")} className="dropdown-item" role='button'>
                    <img width="50px" src="./assets/images/icons/bnb.png" alt="bitcoin" />
                    BNB
                  </a>
                </li>
                <li>
                  <a onClick={() => onChangeSwapFrom("MATIC", "/assets/images/icons/matic.png")} className="dropdown-item" role='button'>
                    <img width="50px" src="./assets/images/icons/matic.png" alt="bitcoin" />
                    MATIC
                  </a>
                </li>
                <li>
                  <a onClick={() => onChangeSwapFrom("BTC", "/assets/images/icons/btc.png")} className="dropdown-item" role='button'>
                    <img width="50px" src="./assets/images/icons/btc.png" alt="bitcoin" />
                    BTC
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
          <div className="swapit__blueblock">
            <div className="blueblock__top">
              <img className="me-1" src="./assets/images/icons/arrow-top-right.svg" alt="" />
              <span>{getTranslatedText("Amount You'll pay")}</span>
            </div>
            <div className="blueblock__bottom">
              <span>1 {active.name} = $ {(active.price).toFixed(2)} USD</span>
            </div>
          </div>
          {backendReferralId
            ? <div className="swapfromto__fees mb-2">
              {getTranslatedText("Sponsor Address")} - {formatWalletAddr(backendReferralId)}
            </div>
            : queryReferralId
              ? <div className="swapfromto__fees mb-2">
                {getTranslatedText("Sponsor Address")} - {formatWalletAddr(queryReferralId)}
              </div>
              : localReferralId
                ? <div className="swapfromto__fees mb-2">
                  {getTranslatedText("Sponsor Address")} - {formatWalletAddr(localReferralId)}
                  <u
                    className='ms-3'
                    style={{ cursor: 'pointer' }}
                    onClick={() => document.getElementById('referral').click()}
                  >
                    {getTranslatedText("Edit")}
                  </u>
                </div>
                : <div className="swapfromto__fees mb-2">
                  {getTranslatedText("Do you have referral id ?")}{' '}
                  <u
                    style={{ cursor: 'pointer' }}
                    onClick={() => document.getElementById('referral').click()}
                  >
                    {getTranslatedText("Yes")}
                  </u>
                </div>
          }
          <div className="input-wrapper">
            <input
              value={amount}
              type="number"
              max="50000"
              className="input-field"
              onChange={onChangeAmount}
              placeholder="Max Amt 50,000 allowed"
            />
            <button className="button-circle" type="submit"> <img width="50px" src={active.imageURL} alt="bitcoin" /> </button>
          </div>
          {amount >= 50000
            ? <div className='mt-2 text-center text-danger fw-bold'>{getTranslatedText("Max 50,000 USD allowed in single transaction")}</div>
            : <></>
          }
        </div>
        <div className="swapfromto__middle" />
        <div className="swapfromto__right">
          <h3>{getTranslatedText("Swap To")}</h3>
          <div className="alarmz-dropdown alarmz-dropdown--disabled">
            <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button">
                <span className="dropdown__selected"><img src="./assets/images/icons/alz.svg" alt="bitcoin" />
                  ALZ-Alarmz</span>
                <span className="button-circle">
                  <svg width={20} height={24} viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.721652 1.96143C0.190667 1.80953 -0.116642 1.25595 0.0352573 0.724961C0.187158 0.193976 0.740745 -0.113333 1.27173 0.0385669L0.721652 1.96143ZM13.2314 24L7.81235 13.8035L19.3522 14.2088L13.2314 24ZM1.27173 0.0385669C5.87561 1.35561 9.10368 3.12003 11.2327 5.60098C13.3734 8.09545 14.2971 11.19 14.545 14.9391L12.5494 15.0711C12.318 11.573 11.4753 8.95478 9.71496 6.90345C7.943 4.83859 5.13547 3.2241 0.721652 1.96143L1.27173 0.0385669Z" fill="#3A3A3A" />
                  </svg>
                </span>
              </button>
              {/* <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="javascript:;"><img src="./assets/images/icons/alm.svg" alt="bitcoin"> ALM-Alarmz</a></li>
              </ul> */}
            </div>
          </div>
          <div className="swapit__greenblock">
            <div className="greenblock__top">
              <span>{getTranslatedText("Amount You'll get for")}</span>
            </div>
            <div className="greenblock__bottom">
              {amount === "" || isNaN(amount)
                ? <span>{0} {active.name} = {0} ALZ</span>
                : <span>{1 * parseInt(amount)} {active.name} = {(parseFloat(estimatedALZ)).toFixed(2)} ALZ</span>
              }
            </div>
          </div>
          <div className="item">
            <div className="checkbox-circle2">
              <input
                value={isChecked}
                type="checkbox"
                id="checkbox-circle2"
                name="check"
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <label htmlFor="checkbox-circle2">
                {getTranslatedText("By clicking you agree to AlarmZ")}
                <a href="./assets/pdf/privacy_policy.pdf" target='__blank' className='fw-bold'> {getTranslatedText("Privacy Policy")} </a>
                {getTranslatedText("and")}
                <a href="./assets/pdf/terms&conditions.pdf" target='__blank' className='fw-bold'> {getTranslatedText("Terms & Conditions")}</a>.
              </label>
            </div>
          </div>
          <a
            onClick={swap}
            role='button'
            className={"button button-yellow mt-2 " + (isChecked && amount <= 50000 ? '' : 'button-disabled')}
          >
            {getTranslatedText("Excited? Buy it now!")}
          </a>
        </div>
      </div>
    </div>
  );
}
