import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  queryReferralId: null,
  backendReferralId: null,
  localReferralId: null,
  address: null,
  language: 'en'
};

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setQueryReferralId: (state, action) => {
      state.queryReferralId = action.payload;
    },

    setBackendReferralId: (state, action) => {
      state.backendReferralId = action.payload;
    },

    setLocalReferralId: (state, action) => {
      state.localReferralId = action.payload;
    },

    setAddress: (state, action) => {
      state.address = action.payload;
    },

    setLanguage: (state, action) => {
      state.language = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setQueryReferralId,
  setBackendReferralId,
  setLocalReferralId,
  setAddress,
  setLanguage
} = mainSlice.actions;

export default mainSlice.reducer;