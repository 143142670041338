import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react'
import { APP_DATA, formatWalletAddr } from '../utils';
import SwapComponent from '../components/SwapComponent';

export default function Swap() {
  const [userData, setUserData] = useState(null);

  const checkUserExists = async () => {
    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      await window.ethereum.request({ method: "eth_chainId" })
      const signer = await provider.getSigner();
      const address = await signer.getAddress();
      const smart_contract_main = new ethers.Contract(APP_DATA.SC_ADDRESS, APP_DATA.SC_ABI, signer);
      const res = await smart_contract_main.doesUserExist(address);
      if (!res) {
        window.location.href = "/connect";
      } else {
        const res1 = await smart_contract_main.UserMaping(address);
        const res2 = await smart_contract_main.UserMaping(res1.sponsor_address);
        const res3 = {
          email: res1.email,
          sponsor_address: res1.sponsor_address,
          sponsor_email: res2.email
        }
        setUserData(res3);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkUserExists();
  }, []);

  const copyToClipBoard = copyMe => {
    navigator.clipboard.writeText(copyMe);
  };

  return (
    <div className="app-layout">
      <div className="app-decorators">
        <div className="position-absolute deco-square"><img src="./assets/images/layout-blue-square.svg" alt="" /></div>
        <div className="rounded-circle position-absolute gradient-blue" />
        <div className="rounded-circle position-absolute gradient-yellow" />
        <div className="position-absolute deco-dots"><img src="./assets/images/layout-dots.svg" alt="" /></div>
      </div>
      {/* Page content starts */}
      <main className="app-content">
        <div className="app-header">
          <div className="container">
            <section className="app-header__inner">
              <div className="logo">
                <img src="./assets/images/Alarmz-logo.svg" alt="Alarmz logo" />
              </div>
              <div className="right-menu">
                <a href="./swap" className="menu-link">
                  <div className="rounded-circle circle circle--yellow">
                    <img src="./assets/images/icons/icon-double-arrows.svg" alt=".." />
                  </div>
                  <span className="text-uppercase fw700">SWAP IT!</span>
                </a>
                <a href="./referandearn" className="menu-link">
                  <div className="rounded-circle circle circle--pink">
                    <img src="./assets/images/icons/icon-dollar.svg" alt=".." />
                  </div>
                  <span className="text-uppercase fw700">REFER AND EARN</span>
                </a>
                <div className="user-info">
                  <div className="rounded-circle dp-cover">
                    <img src="./assets/images/pixelated-dp.png" alt="User DP" />
                  </div>
                  <div className="info">
                    <div className="email">{userData?.email}</div>
                    <div className="email-sponsor">
                      <strong className="fw600">Sponsor Address:</strong>
                      <span>{userData?.sponsor_email}</span>
                      <button
                        onClick={() => copyToClipBoard(userData?.sponsor_email)}
                        className="rounded-circle border-0 button button-yellow"
                      >
                        <img src="./assets/images/icons/icon-copy.svg" alt="Copy icon" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-none d-lg-inline-flex">
                  <a href="./app" className="logout" title="Logout">
                    <img src="./assets/images/icons/icon-logout.svg" alt="..." />
                  </a>
                </div>
              </div>
              <div className="d-inline-flex d-lg-none align-items-center">
                <button className="btn border-0 p-1 app-menu-toggle" type="button">
                  <img src="./assets/images/icons/icon-menu-dots.svg" alt="" />
                </button>
                <div>
                  <a href="./connect-wallet.html" className="logout ms-3" title="Logout">
                    <img src="./assets/images/icons/icon-logout.svg" alt="..." />
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
        <section className="swapit">
          <div className="container">
            <SwapComponent name='swap' />
          </div>
        </section>
      </main>
    </div>
  )
}
