import abi from "./abis/swap_abi.json";
import abi_usdt from "./abis/usdt_abi.json";

export const APP_DATA = {
  SC_ADDRESS: "0x2c342Ad69ABd2406cd653094971d58F90Fc1d4c8",
  // SC_ADDRESS: "0xe631aC1B1f853e9e4B9284ba07473Ae220A983ef",  //testnet
  SC_ABI: abi,
  SC_ADDRESS_USDT: "0x55d398326f99059fF775485246999027B3197955",
  // SC_ADDRESS_USDT: "0x2e6F649d16d77d59c49649687f9D6CdD25FDeBED",  //testnet
  SC_USDT_ABI: abi_usdt,
  RPC_URL: 'https://bsc-dataseed1.binance.org/',
  // RPC_URL: 'https://data-seed-prebsc-1-s1.binance.org:8545/',  //testnet
  network_name: 'bsc',
  // network_name: 'bsctestnet',  //testnet
}

export const formatWalletAddr = (address) => {
  if (address) {
    return address.substr(0, 5) + "..." + address.substr(address.length - 4, 4);
  } else return "";
}