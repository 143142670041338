import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Swap from './pages/Swap';
import Registration from './pages/Registration';
import ReferAndEarn from "./pages/ReferAndEarn";
import ConnectApp from "./pages/ConnectApp";
import { ToastContainer } from "react-toastify";

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { bsc, bscTestnet } from 'wagmi/chains';

const App = () => {
  const chains = [bsc, bscTestnet];
  const projectId = '002a6f95291a4edb1732789c2d95582b';

  const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient
  });

  const ethereumClient = new EthereumClient(wagmiConfig, chains);

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <BrowserRouter>
          <ToastContainer />
          <Routes>
            <Route exact path='/app' element={<ConnectApp />} />
            <Route exact path='/register' element={<Registration />} />
            <Route exact path='/swap' element={<Swap />} />
            <Route exact path='/referandearn' element={<ReferAndEarn />} />
            <Route exact path='/' element={<Home />} />
          </Routes>
        </BrowserRouter>
      </WagmiConfig>

      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
      />
    </>
  );
};

export default App;