import React, { useEffect, useState } from 'react'
import SwapComponent from '../components/SwapComponent';
import Cookies1 from '../components/cookies';
import { ethers, formatEther } from 'ethers';
import { APP_DATA, formatWalletAddr } from "../utils";
import { useDispatch, useSelector } from 'react-redux';
import { setAddress, setBackendReferralId, setLanguage, setLocalReferralId, setQueryReferralId } from '../store/mainSlice';
import copy from 'copy-to-clipboard';
import { useWeb3Modal } from '@web3modal/react';
import { useAccount } from 'wagmi';
import $ from 'jquery';
import { ReactFitty } from 'react-fitty';

export default function Home() {
  const targetDate = new Date("2023-09-29T16:00:00");

  const [timer, setTimer] = useState({
    days: 0,
    hours: 0,
    mins: 0,
    secs: 0
  });

  const updateTimer = () => {
    // Get the current date and time
    const startDate = new Date("2023-06-28T16:00:00");
    const currentDate = new Date();

    // Calculate the remaining time in seconds
    const remainingTime = Math.floor((targetDate - currentDate) / 1000);

    // Calculate the number of days, hours, minutes, and seconds
    if (remainingTime < 0 || currentDate < startDate) {
      const days = 0;
      const hours = 0;
      const mins = 0;
      const secs = 0;
      setTimer({ days, hours, mins, secs });
    } else {
      const days = Math.floor(remainingTime / (60 * 60 * 24));
      const hours = Math.floor((remainingTime % (60 * 60 * 24)) / (60 * 60));
      const mins = Math.floor((remainingTime % (60 * 60)) / 60);
      const secs = remainingTime % 60;
      setTimer({ days, hours, mins, secs });
    }
  };

  useEffect(() => {
    const myInterval = setInterval(updateTimer, 1000);

    return () => {
      clearInterval(myInterval);
    }
  }, []);

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = `//${window.location.host}/assets/scripts/landing.js`;
    script1.async = true;
    document.body.appendChild(script1);

    const script2 = document.createElement('script');
    script2.src = `//${window.location.host}/assets/scripts/scripts.js`;
    script2.async = true;
    document.body.appendChild(script2);

    const script3 = document.createElement('script');
    script3.src = `//js-na1.hs-scripts.com/40057695.js`;
    script3.async = true;
    document.body.appendChild(script3);

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
      document.body.removeChild(script3);
    }
  }, []);

  const openSocialMediaLink = (e, link) => {
    e.preventDefault();
    window.open(link);
  };

  const [nameForQuery, setNameForQuery] = useState();
  const [mailForQuery, setMailForQuery] = useState();
  const [descriptionForQuery, setDescriptionForQuery] = useState();

  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const submitQuery = async () => {
    try {
      if (mailForQuery.match(mailformat)) {
        const res = await fetch('https://alarmz.io/api2/main/contact-form', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 'name': nameForQuery, 'email': mailForQuery, 'message': descriptionForQuery })
        });
        const data = await res.json();
        console.log(data)
        if (data) {
          document.getElementById('query').click();
          setNameForQuery('');
          setDescriptionForQuery('');
          setMailForQuery('');
        }
      } else {
        alert('You have entered an invalid email address!');
      }
    } catch (error) {
      console.log(error)
    }
  };

  const [faqQuestion, setFaqQuestion] = useState();

  const justAsk = async () => {
    try {
      const res = await fetch('https://alarmz.io/api2/main/just-ask', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'message': faqQuestion })
      });
      const data = await res.json();
      console.log(data)
      if (data) {
        document.getElementById('jusask').click();
        setFaqQuestion('');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [subscribeEmail, setSubscribeEmail] = useState();

  const subscribeNews = async () => {
    try {
      if (subscribeEmail.match(mailformat)) {
        const res = await fetch('https://alarmz.io/api2/main/news-letter', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 'email': subscribeEmail })
        });
        const data = await res.json();
        console.log(data)
        if (data) {
          document.getElementById('subscribeNews').click();
          setSubscribeEmail('');
        }
      } else {
        alert('You have entered an invalid email address!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [subscribeEmailMenu, setSubscribeEmailMenu] = useState();

  const subscribeNewsMenu = async () => {
    try {
      if (subscribeEmailMenu.match(mailformat)) {
        const res = await fetch('https://alarmz.io/api2/main/news-letter', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 'email': subscribeEmailMenu })
        });
        const data = await res.json();
        console.log(data)
        if (data) {
          document.getElementById('subscribeNews').click();
          setSubscribeEmailMenu('');
          $('.mobMenu2').addClass('mobMenu2--animating');
          $('.mobMenu2').toggleClass('mobMenu2--active');
          setTimeout(function () {
            $('.mobMenu2').removeClass('mobMenu2--animating');
          }, 1000);
        }
      } else {
        alert('You have entered an invalid email address!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [joinWaitListEmail, setJoinWaitListEmail] = useState();

  const joinWaitList = async (e) => {
    try {
      e.preventDefault();
      if (joinWaitListEmail.match(mailformat)) {
        const res = await fetch('https://alarmz.io/api2/main/news-letter', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 'email': joinWaitListEmail })
        });
        const data = await res.json();
        console.log(data)
        if (data) {
          document.getElementById('joinwaitlist').click();
          setJoinWaitListEmail('');
        }
      } else {
        alert('You have entered an invalid email address!');
      }
    } catch (error) {
      console.log(error);
    }

  };

  const [raisedALZ, setRaisedALZ] = useState(0);
  const [rasiedUSD, setRasiedUSD] = useState(0);
  const numberOfCircles = Math.round(raisedALZ / (12500000 / 12));

  const getTokenInfo = async () => {
    try {
      const provider = new ethers.JsonRpcProvider(APP_DATA.RPC_URL);
      // await window.ethereum.request({ method: "eth_chainId" })
      // const signer = await provider.getSigner();

      const smart_contract_main = new ethers.Contract(APP_DATA.SC_ADDRESS, APP_DATA.SC_ABI, provider);

      const temp1 = await smart_contract_main.tokensoldNOW();
      // const temp3 = formatEther(temp1).toFixed(2);
      setRaisedALZ(parseInt(formatEther(temp1)));

      const temp2 = await smart_contract_main.tokensSold();
      setRasiedUSD(formatEther(temp2));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTokenInfo();
  }, []);

  useEffect(() => {
    // document.getElementById('sale').click();
  }, []);

  const dispatch = useDispatch();

  const localReferralId = useSelector(state => state.main.localReferralId);

  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();

  const walletConnect = async () => {
    // const provider = new ethers.BrowserProvider(window.ethereum);
    // const signer = await provider.getSigner();
    // const address = await signer.getAddress();
    // dispatch(setAddress(address));
    try {
      open();
    } catch (error) {
      console.log(error);
    }
  };

  const copyReferralLink = async () => {
    document.getElementsByClassName('copy')[0].classList.add('active');
    setTimeout(() => {
      document.getElementsByClassName('copy')[0].classList.remove('active');
    }, 2500);
    copy(`https://${window.location.host}/?referid=${address}#swapit`);
  };

  const [referralId1, setReferralId1] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const referid = queryParams.get('referid');
    if (referid) {
      dispatch(setQueryReferralId(referid));
      setReferralId1(referid);
    }
  }, []);

  const logout = async () => {
    try {
      // dispatch(setAddress(null));
      open();
    } catch (error) {
      console.log(error);
    }
  };

  const [businessVol, setBusinessVol] = useState(0);

  const getBusinessVol = async () => {
    try {
      if (address) {
        const res = await fetch(`https://alarmz.io/api2/main/get-total?sponsor_address=${address}`);
        // const res = await fetch(`https://alarmz.io/api2/main/get-total?sponsor_address=${address}&network_name=${APP_DATA.network_name}`);
        const data = await res.json();
        data.data.usdt_total
          ? setBusinessVol(data.data.usdt_total)
          : setBusinessVol(0);
      } else {
        setBusinessVol(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [error1, setError1] = useState();

  const registerAccount = async () => {
    try {
      const res = await fetch(`https://alarmz.io/api2/account/register`, {
        'method': 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'wallet_address': address, 'sponsor_address': referralId1 }),
      });
      const data = await res.json();
      console.log(data);
      if (data.sponsor) {
        dispatch(setBackendReferralId(data.sponsor));
        dispatch(setLocalReferralId(null));
      }
      if (data.error) {
        setError1(data.error);
        document.getElementById('randomError').click();
      }
    } catch (error) {
      console.log(error);
      setError1('error');
      document.getElementById('randomError').click();
    }
  };

  useEffect(() => {
    if (isConnected) {
      registerAccount();
    }
  }, [isConnected]);

  useEffect(() => {
    getBusinessVol();
  }, [address]);

  const copyReferralLink1 = async () => {
    document.getElementsByClassName('copy1')[0].classList.add('active');
    setTimeout(() => {
      document.getElementsByClassName('copy1')[0].classList.remove('active');
    }, 2500);
    copy('0xE2c7d18ED3f66Dd4E2e809c6B0779d01b004f942');
  };

  const selectedLanguage = useSelector(state => state.main.language);

  const getTranslatedText = (textKey) => {
    try {
      const translations = require(`../translations/${selectedLanguage}.json`);
      return translations[textKey] || textKey;
    } catch (error) {
      console.error(`Translation error: ${error}`);
      return textKey;
    }
  };

  useEffect(() => {
    dispatch(setLanguage('en'));
  }, []);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const changeLanguage = (language) => {
    dispatch(setLanguage(language));
    setIsDropdownOpen(false);
  }

  return (
    <body className="layout-landing">
      <header className="header">
        <div className="container" style={{ paddingRight: '5px' }}>
          <nav>
            <ul className="header__nav-left">
              <li>
                <a className="header__logo" href="">
                  <img src="./assets/images/Alarmz-logo.svg" alt="Alarmz logo" />
                </a>
              </li>
            </ul>
            <ul className="header__nav-right">
              <li>
                <a href="#platform">{getTranslatedText("Platform")}</a>
              </li>
              <li>
                <a href="#benefits">{getTranslatedText("Benefits")}</a>
              </li>
              <li>
                <a href="#swapit">{getTranslatedText("Token Sale")}</a>
              </li>
              <li>
                <a href="#roadmap">{getTranslatedText("Roadmap")}</a>
              </li>
              <li>
                <a href="#faq">{getTranslatedText("FAQ")}</a>
              </li>
              <li>
                <a href="#contactus">{getTranslatedText("Contacts")}</a>
              </li>
              <li>
                <div className='d-flex align-items-center me-2'>
                  {address
                    ? <div className='d-flex align-items-center'>
                      <div className='wallet-connect d-flex align-items-center'>
                        <div className='d-flex flex-column'>
                          <div>{formatWalletAddr(address)}</div>
                          <div style={{ fontSize: '10px' }}>{getTranslatedText("Business Vol:")} {businessVol}</div>
                        </div>
                        <div style={{ position: 'relative' }}>
                          <div className='copy ms-2' onClick={copyReferralLink}>
                            <img src='./assets/images/copy.svg' />
                          </div>
                        </div>
                      </div>
                      <div className='black-button ms-2 logout' onClick={logout}>
                        <img className='ms-1' style={{ width: '20px' }} src='./assets/images/logout.svg' />
                      </div>
                    </div>
                    : <div className="mobile-show">
                      <a
                        className="button button-yellow"
                        href="javascript:void(0)"
                        onClick={walletConnect}
                      >
                        <span className="rounded-circle bg-white">
                          <img src="./assets/images/icons/rocket.svg" alt="" />
                        </span>
                        {getTranslatedText("Wallet Connect")}
                      </a>
                    </div>
                  }
                  <button
                    className="btn border-0 p-2 d-inline-block d-md-none menu-toggle"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#fullModal"
                  >
                    <img style={{ width: '24px', height: '24px' }} src="./assets/images/icons/icon-menu-dots.svg" alt="" />
                  </button>
                  <div className='language-dropdown-container'>
                    <div className='black-button' onClick={toggleDropdown} >
                      <img style={{ width: '20px' }} src='./assets/images/language.svg' />
                    </div>
                    {isDropdownOpen && (
                      <div className="language-dropdown-options">
                        <span
                          className='language-dropdown-option'
                          onClick={() => changeLanguage('en')}
                          style={{ backgroundColor: selectedLanguage === 'en' ? '#fffab0' : '' }}
                        >
                          <img src='./assets/images/flag/usa.png' />
                          English
                        </span>
                        <span
                          className='language-dropdown-option'
                          onClick={() => changeLanguage('hi')}
                          style={{ backgroundColor: selectedLanguage === 'hi' ? '#fffab0' : '' }}
                        >
                          <img src='./assets/images/flag/india.png' />
                          Hindi
                        </span>
                        <span
                          className='language-dropdown-option'
                          onClick={() => changeLanguage('ko')}
                          style={{ backgroundColor: selectedLanguage === 'ko' ? '#fffab0' : '' }}
                        >
                          <img src='./assets/images/flag/korea.png' />
                          Korean
                        </span>
                        <span
                          className='language-dropdown-option'
                          onClick={() => changeLanguage('ja')}
                          style={{ backgroundColor: selectedLanguage === 'ja' ? '#fffab0' : '' }}
                        >
                          <img src='./assets/images/flag/japan.png' />
                          Japanese
                        </span>
                        <span
                          className='language-dropdown-option'
                          onClick={() => changeLanguage('zn')}
                          style={{ backgroundColor: selectedLanguage === 'zn' ? '#fffab0' : '' }}
                        >
                          <img src='./assets/images/flag/china.png' />
                          Chinese
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      {/* Mobile Menu Modal */}
      <div className="mobMenu2">
        <div className="mobMenu2__overlay"></div>
        <div className="mobMenu2__inner">
          <div className="mobMenu2__head">
            <a className="mobMenu2__logo" href="">
              <img src="./assets/images/animating/logo/logo.svg" alt="logo" />
            </a>
            <a className="mobMenu2__close" href="javascript:void(0)">
              {getTranslatedText("Menu")} <img src="./assets/images/menu/close-icon.svg" alt="close" />
            </a>
          </div>
          <div className="mobMenu2__body">
            <h2>{getTranslatedText("Navigation")}</h2>
            <ul>
              <li>
                <a href="#platform">{getTranslatedText("Platform")}</a>
              </li>
              <li>
                <a href="#benefits">{getTranslatedText("Benefits")}</a>
              </li>
              <li>
                <a href="#swapit">{getTranslatedText("Token Sale")}</a>
              </li>
              <li>
                <a href="#roadmap">{getTranslatedText("Roadmap")}</a>
              </li>
              <li>
                <a href="#faq">{getTranslatedText("FAQ")}</a>
              </li>
              <li>
                <a href="#contactus">{getTranslatedText("Contacts")}</a>
              </li>
              <li onClick={logout} className='mobMenu2__close' style={{ padding: '0px' }}>
                <a>{getTranslatedText("Log out")}</a>
              </li>
            </ul>
            <div className="mobMenu2__appStore">
              <a
                className="button button-apple"
                href="javascript:void(0)"
                data-bs-toggle="modal"
                data-bs-target="#subscribeModal"
              >
                <img src="./assets/images/btn-apple.svg" alt="..." />
              </a>
              <a
                className="button button-apple"
                href="javascript:void(0)"
                data-bs-toggle="modal"
                data-bs-target="#subscribeModal"
              >
                <img src="./assets/images/btn-google.svg" alt="..." />
              </a>
            </div>
            <h2>{getTranslatedText("Subscribe to our newsletter")}</h2>
            <p>
              {getTranslatedText("Web 3 Unified Communication Protocol enhancing Crypto Communications Channel")}{" "}
            </p>
            <div className="mt-4 form-group form-group--withIconBtn">
              <input
                required=""
                type="text"
                placeholder={getTranslatedText("Your Email Address")}
                className="form-control"
                value={subscribeEmailMenu}
                onChange={(e) => setSubscribeEmailMenu(e.target.value)}
              />
              <button className="btn p-0 border-0 rounded-circle btn--yellow-circular" onClick={subscribeNewsMenu}>
                <img
                  src="./assets/images/icons/icon-newsletter.svg"
                  alt="Newsletter icon"
                />
              </button>
            </div>
            <div className="mobMenu2__social">
              <h2>{getTranslatedText("FOLLOW US")}</h2>
              <ul>
                <li>
                  <button
                    type="button"
                    className="rounded-circle p-0 ts03 btn social__btn"
                    onClick={(e) => openSocialMediaLink(e, 'https://twitter.com/Alarmzprotocol')}
                  >
                    <img src="./assets/images/icons/icon-twitter.svg" alt="" />
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="rounded-circle p-0 ts03 btn social__btn"
                    onClick={(e) => openSocialMediaLink(e, 'https://www.instagram.com/alarmzprotocol/')}
                  >
                    <img src="./assets/images/icons/icon-instagram.svg" alt="" />
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="rounded-circle p-0 ts03 btn social__btn"
                    onClick={(e) => openSocialMediaLink(e, 'https://t.me/alarmzprotocol')}
                  >
                    <img src="./assets/images/icons/icon-telegram.svg" alt="" />
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="rounded-circle p-0 ts03 btn social__btn"
                    onClick={(e) => openSocialMediaLink(e, 'https://discord.gg/T4GT8kMe')}
                  >
                    <img src="./assets/images/icons/icon-discord.svg" alt="" style={{ width: '25px' }} />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <section className="section-hero">
        <div className="deco-yellow" />
        <div className="deco-blue" />
        <div className="deco-orange" />
        <div className="deco-rect">
          <img alt="" src="./assets/images/deco-rect1.svg" />
        </div>
        <div className="container">
          <div className="row g-0 align-items-center">
            <div className="col-lg-6 col-left">
              <h1 className="h1">Alarmz.io</h1>
              <h1 className="text1">{getTranslatedText("Web 3 Unified Communication Protocol !")}</h1>
              <p className="text2">
                {getTranslatedText("Built on a decentralized, trustless infrastructure to enhance multi-chain communication ecosystem for crypto exchanges, decentralized apps, wallets, and services through platform-agnostic, communication and collaboration between Web3 applications and users.")}
              </p>
              <div className="button-wrapper1">
                <a className="button button-yellow active" href="/assets/pdf/alarmz_pitchdeck.pdf" target='_blank'>
                  <span>{getTranslatedText("FIND US HERE")}</span>
                  <img src="./assets/images/icons/icon-arrow-down.svg" alt="..." />
                </a>

                <button
                  type="button"
                  className="rounded-circle p-0 ts03 btn social__btn video vpop"
                  data-type="youtube"
                  data-id="6yiGX8iCFlA"
                  data-autoplay='true'
                >
                  <img src="./assets/images/icons/video.svg" alt="" style={{ marginLeft: '4px' }} />
                </button>
              </div>

              <div className="button-wrapper2">
                <a className="button button-apple" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#subscribeModal">
                  <img src="./assets/images/btn-apple.svg" alt="..." />
                </a>
                <a className="button button-apple" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#subscribeModal">
                  <img src="./assets/images/btn-google.svg" alt="..." />
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="timecounter">
                <h2 className="h2 mb-0">
                  <span>{getTranslatedText("Token")}</span>
                  <img src="./assets/images/sale.svg" alt="..." />
                  <span>{getTranslatedText("End In!")}</span>
                </h2>

                <p className="section-desc mb-4">
                  <h1 className="text1 mb-0">{getTranslatedText("ALZ Smart Contract Address")}</h1>
                  <div className='wallet-connect d-flex align-items-center justify-content-center mt-2' style={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}>
                    <div className='d-flex flex-column'>
                      <div className='smartContractAddress'>0xE2c7d18ED3f66Dd4E2e809c6B0779d01b004f942</div>
                    </div>
                    <div style={{ position: 'relative' }}>
                      <a className='copy ms-2' href="https://bscscan.com/address/0xe2c7d18ed3f66dd4e2e809c6b0779d01b004f942" target='_blank' >
                        <img src='./assets/images/up-arrow.svg' style={{ width: '20px' }} />
                      </a>
                    </div>
                    <div style={{ position: 'relative' }}>
                      <div className='copy1 ms-2' onClick={copyReferralLink1}>
                        <img src='./assets/images/copy.svg' />
                      </div>
                    </div>
                  </div>
                </p>

                <div className="timecounter__ticker">
                  <div className="timecounter__days timecounter__single">
                    <div className="timecounter__number">{timer.days}</div>
                    <div className="timecounter__text">{getTranslatedText("Days")}</div>
                  </div>
                  <div className="timecounter__days timecounter__single">
                    <div className="timecounter__number">{timer.hours}</div>
                    <div className="timecounter__text">{getTranslatedText("Hours")}</div>
                  </div>
                  <div className="timecounter__days timecounter__single">
                    <div className="timecounter__number">{timer.mins}</div>
                    <div className="timecounter__text">{getTranslatedText("Mins")}</div>
                  </div>
                  <div className="timecounter__days timecounter__single">
                    <div className="timecounter__number">{timer.secs}</div>
                    <div className="timecounter__text">{getTranslatedText("Secs")}</div>
                  </div>
                </div>

                <div className="timecounter__indicator">
                  <div className="indicator-title" style={{ marginBottom: '0' }}>
                    <span>{getTranslatedText("Presale")}</span>
                    <span>{getTranslatedText("Softcap")}</span>
                    <span>{getTranslatedText("Hardcap")}</span>
                  </div>
                  <div className="indicator-title">
                    {/* <span>0 ALZ</span> */}
                    <span>{312500 + raisedALZ} ALZ</span>
                    <span style={{ marginLeft: '0px' }}>2 Mn ALZ</span>
                    <span>12.5 Mn ALZ</span>
                  </div>
                  <div className="colorstrip">
                    <span className={"active"} />
                    {/* <span className={1 <= numberOfCircles ? "active" : ''} /> */}
                    <span className={2 <= numberOfCircles ? "active" : ''} />
                    <span className={3 <= numberOfCircles ? "active" : ''} />
                    <span className={4 <= numberOfCircles ? "active" : ''} />
                    <span className={4 <= numberOfCircles ? "active" : ''} />
                    <span className={5 <= numberOfCircles ? "active" : ''} />
                    <span className={6 <= numberOfCircles ? "active" : ''} />
                    <span className={7 <= numberOfCircles ? "active" : ''} />
                    <span className={8 <= numberOfCircles ? "active" : ''} />
                    <span className={9 <= numberOfCircles ? "active" : ''} />
                    <span className={10 <= numberOfCircles ? "active" : ''} />
                    <span className={11 <= numberOfCircles ? "active" : ''} />
                    <span className={12 <= numberOfCircles ? "active" : ''} />
                  </div>
                  <div className="indicator-totals justify-content-center">
                    <span>
                      <div style={{ fontSize: '1.2rem' }}>{getTranslatedText("Total USD Raised:")}
                        {/* <span> 0</span> USD</div> */}
                        <span> {31250 + Number(rasiedUSD)}</span> USD
                      </div>
                    </span>
                    {/* <span>
                      <div>Target-</div>
                      <div>
                        <span>1,250,000</span> USD
                      </div>
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="partners">
        <div className="deco-orange" />
        <div className="deco-circle">
          <img src="./assets/images/deco-circle2.svg" alt="..." />
        </div>
        <div className="container">
          <div className="section-toptitle">
            <img src="./assets/images/partners-toptitle.svg" alt="partners-top" />
          </div>
          <div className="partners-loop-carousel owl-carousel owl-theme">
            <div className="item" onClick={(e) => openSocialMediaLink(e, 'https://www.accesswire.com/765008/Alarmz-Introduces-Revolutionary-Multi-Channel-Communication-Solution-to-Transform-the-Crypto-Industry')}>
              <div className="partners__single bg bg-green">
                <img src="./assets/images/partners/media/accesswire.svg" alt="..." />
              </div>
            </div>
            <div className="item" onClick={(e) => openSocialMediaLink(e, 'https://www.aninews.in/news/business/business/alarmz-launches-pre-sale-aims-to-revolutionize-crypto-risk-mitigation-management20230715161411/')}>
              <div className="partners__single bg bg-orange">
                <img src="./assets/images/partners/media/ani.svg" alt="..." />
              </div>
            </div>
            <div className="item" onClick={(e) => openSocialMediaLink(e, 'https://www.bloomberg.com/press-releases/2023-06-30/alarmz-introduces-revolutionary-multi-channel-communication-solution-to-transform-the-crypto-industry')}>
              <div className="partners__single bg bg-yellow">
                <img src="./assets/images/partners/media/bloomberg.svg" alt="..." />
              </div>
            </div>
            <div className="item" onClick={(e) => openSocialMediaLink(e, 'https://www.business-standard.com/content/press-releases-ani/alarmz-launches-pre-sale-aims-to-revolutionize-crypto-risk-mitigation-management-123071500424_1.html')}>
              <div className="partners__single bg bg-green">
                <img src="./assets/images/partners/media/business-standard.svg" alt="..." />
              </div>
            </div>
            <div className="item" onClick={(e) => openSocialMediaLink(e, 'https://www.devdiscourse.com/article/business/2524251-alarmz-launches-pre-sale-aims-to-revolutionize-crypto-risk-mitigation-management')}>
              <div className="partners__single bg bg-orange">
                <img src="./assets/images/partners/media/devdiscourse.svg" alt="..." />
              </div>
            </div>
            <div className="item" onClick={(e) => openSocialMediaLink(e, 'https://www.digitaljournal.com/pr/news/accesswire/alarmz-introduces-revolutionary-multi-channel-communication-solution-to-transform-the-crypto-industry')}>
              <div className="partners__single bg bg-yellow">
                <img src="./assets/images/partners/media/digital-journal.svg" alt="..." />
              </div>
            </div>
            <div className="item" onClick={(e) => openSocialMediaLink(e, 'http://www.journeyline.in/newsdet.aspx?q=259130')}>
              <div className="partners__single bg bg-green">
                <img src="./assets/images/partners/media/journey-line.svg" alt="..." />
              </div>
            </div>
            <div className="item" onClick={(e) => openSocialMediaLink(e, 'https://www.latestly.com/agency-news/business-news-alarmz-launches-pre-sale-aims-to-revolutionize-crypto-risk-mitigation-management-5267922.html')}>
              <div className="partners__single bg bg-orange">
                <img src="./assets/images/partners/media/latestly.svg" alt="..." />
              </div>
            </div>
            <div className="item" onClick={(e) => openSocialMediaLink(e, 'https://www.lokmattimes.com/business/alarmz-launches-pre-sale-aims-to-revolutionize-crypto-risk-mitigation-management/')}>
              <div className="partners__single bg bg-yellow">
                <img src="./assets/images/partners/media/lokmat-Times.svg" alt="..." />
              </div>
            </div>
            <div className="item" onClick={(e) => openSocialMediaLink(e, 'https://www.marketwatch.com/press-release/alarmz-introduces-revolutionary-multi-channel-communication-solution-to-transform-the-crypto-industry-fd7cf63b')}>
              <div className="partners__single bg bg-green">
                <img src="./assets/images/partners/media/marketwatch.svg" alt="..." />
              </div>
            </div>
            <div className="item" onClick={(e) => openSocialMediaLink(e, 'https://www.livemint.com/brand-stories/alarmz-launches-pre-sale-aims-to-reshape-crypto-risk-mitigation-management-11689569612037.html')}>
              <div className="partners__single bg bg-orange">
                <img src="./assets/images/partners/media/mint.svg" alt="..." />
              </div>
            </div>
            <div className="item" onClick={(e) => openSocialMediaLink(e, 'https://www.morningstar.com/news/accesswire/765008msn/alarmz-introduces-revolutionary-multi-channel-communication-solution-to-transform-the-crypto-industry')}>
              <div className="partners__single bg bg-yellow">
                <img src="./assets/images/partners/media/morningstar.svg" alt="..." />
              </div>
            </div>
            <div className="item">
              <div className="partners__single bg bg-green">
                <img src="./assets/images/partners/media/new-kerala.svg" alt="..." />
              </div>
            </div>
            <div className="item" onClick={(e) => openSocialMediaLink(e, 'https://techbullion.com/alarmz-revolutionizes-crypto-communication-with-its-decentralized-unified-protocol/')}>
              <div className="partners__single bg bg-orange">
                <img src="./assets/images/partners/media/techbullion.svg" alt="..." />
              </div>
            </div>
            <div className="item" onClick={(e) => openSocialMediaLink(e, 'https://www.theweek.in/wire-updates/business/2023/07/15/dcm7-alarmz.html')}>
              <div className="partners__single bg bg-yellow">
                <img src="./assets/images/partners/media/the-week.svg" alt="..." />
              </div>
            </div>
            <div className="item" onClick={(e) => openSocialMediaLink(e, 'https://theprint.in/ani-press-releases/alarmz-launches-pre-sale-aims-to-revolutionize-crypto-risk-mitigation-management/1671022/')}>
              <div className="partners__single bg bg-green">
                <img src="./assets/images/partners/media/theprint.svg" alt="..." />
              </div>
            </div>
            <div className="item" onClick={(e) => openSocialMediaLink(e, 'https://finance.yahoo.com/news/alarmz-introduces-revolutionary-multi-channel-225300054.html')}>
              <div className="partners__single bg bg-orange">
                <img src="./assets/images/partners/media/yahoofinance.svg" alt="..." />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whoweare" id='platform'>
        <div className="container">
          <div className="row">
            <div className="align-self-lg-center col-lg-6">
              <img src="./assets/images/alarmz-token-ticker-ALZ.svg" alt="Alarmz-ALZ Symbol, Web 3 Unified Communication Protocol" />
            </div>
            <div className="col-lg-6">
              <div className="section-toptitle">
                <img src="./assets/images/whoweare-toptitle.svg" alt="footer" />
              </div>
              <h2 className="section-title h2">
                {getTranslatedText("Who We Are")} <span className="color-yellow">?</span>
              </h2>
              <div className="bullet">
                {getTranslatedText("Introducing Alarmz: Web 3 Unified Communication Protocol powered by DAG-based blockchain tech.")}
              </div>
              <div className="blockquote">
                {getTranslatedText("Our cloud-based platform boosts secure and cost-effective communication for crypto exchanges. Elevate the crypto community experience with SMS, IVR, email, OTP, WhatsApp, and push notifications. Stay informed on transactions across chains for timely communication. Accessible via cloud services and web/mobile apps, revolutionizing crypto communication.")}
              </div>
              <div className="bullet">
                {getTranslatedText("Alarmz Protocol solutions redefine the industry that prioritize enhanced confidentiality, interoperability, traceability, safety, and dependability.")}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whatwegive" id='benefits'>
        <div className="deco-yellow" />
        <div className="deco-triangle">
          <img alt="" src="./assets/images/milestone/triangle.png" />
        </div>
        <div className="container">
          <div className="section-toptitle">
            <img src="./assets/images/whatwegive-toptitle.svg" alt="footer" />
          </div>
          <h2 className="section-title h2">
            {getTranslatedText("What We give")} <span className="color-orange">?</span>
          </h2>
          <div className="justify-content-md-center row g-1 g-lg-4">
            <div className="col-md-4 col-lg-4">
              <div className="whatwegive__item">
                <div className="whatwegive__img">
                  <img src="./assets/images/whatwegive/alarmz-transaction.svg" alt="Alarmz-Real Time Alarm of Transaction" />
                </div>
                <div className="whatwegive__title">
                  <h3>{getTranslatedText("Real Time Alarm of Transaction")}</h3>
                </div>
                <div className="bullet bullet--yellow">
                  {getTranslatedText("Connect with users everywhere they want to interact with crypto exchanges, decentralized apps, wallets.")}
                </div>
                {/* <div className="bullet bullet--yellow">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint.
                </div> */}
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="whatwegive__item">
                <div className="whatwegive__img">
                  <img src="./assets/images/whatwegive/alarmz-data-protection.svg" alt="Alarmz-Enhanced Data Protection" />
                </div>
                <div className="whatwegive__title">
                  <h3>{getTranslatedText("Enhanced Data Protection")}</h3>
                </div>
                <div className="bullet bullet--orange">
                  {getTranslatedText("Ensure data protection, compliance on the ISO 27001 framework, confidentiality, integrate safely with technologies for payments.")}
                </div>
                {/* <div className="bullet bullet--orange">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint.
                </div> */}
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="mb-0 whatwegive__item">
                <div className="whatwegive__img">
                  <img src="./assets/images/whatwegive/alarmz-dapps-holding.svg" alt="Alarmz-Control your DAPP Holdings" />
                </div>
                <div className="whatwegive__title">
                  <h3>{getTranslatedText("Control your DAPP Holdings")}</h3>
                </div>
                <div className="bullet bullet--blue">
                  {getTranslatedText("Get multi-channel alerts for dApps holders in dynamic liquidity pools. Stay informed pre and post transactions across chains.")}
                </div>
                {/* <div className="bullet bullet--blue">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint.
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tokenomics">
        <div className="deco-orange" />
        <div className="deco-blue" />
        <div className="deco-yellow" />
        <div className="deco-rect">
          <img alt="" src="./assets/images/deco-rect1.svg" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-toptitle">
                <img src="./assets/images/tokenomics-toptitle.svg" alt="footer" />
              </div>
              <h2 className="section-title h2">
                {getTranslatedText("Whitepaper")} <span className="color-blue">!</span>
              </h2>
              <div className="button-wrapper">
                <a className="button button-blue active" href="./assets/pdf/alarmz_whitepaper.pdf" target='__blank'>
                  <span>{getTranslatedText("Download Here")}</span>
                  <img src="./assets/images/icons/icon-arrow-down.svg" alt="..." />
                </a>
              </div>
              <p className="">
                {getTranslatedText("Experience the future of crypto communication with Alarmz. Join us in revolutionizing secure and decentralized communication within the sector. Discover seamless integration and enhanced collaboration in our white paper.")}
              </p>
              <div className="button-wrapper">
                <a className="button button-green active" href="./assets/pdf/Alarmz_lightpaper.pdf" target='__blank'>
                  <span>{getTranslatedText("Lightpaper")}</span>
                  <img src="./assets/images/icons/icon-arrow-down.svg" alt="..." />
                </a>
              </div>
              <p>
                {getTranslatedText("Join forces with us to shape the future of communication in the crypto industry. Together, we empower individuals and businesses to thrive in this exciting digital ecosystem.")}
              </p>
            </div>
            <div className="text-center col-lg-6">
              <img
                className="tokenomics__img"
                src="./assets/images/tokenomics.svg"
                alt="Alarmz-Tokenization"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="distribution">
        <div className="container">
          <div className="section-toptitle">
            <img src="./assets/images/distribution-toptitle.svg" alt="footer" />
          </div>
          <h2 className="section-title h2">
            {getTranslatedText("Distribution")} <span className="color-yellow">!</span>
          </h2>
          <p className="section-desc">
            {getTranslatedText("Don't miss out on this unique opportunity to join our thriving network and earn ALZ token rewards for your contributions!")}
          </p>
        </div>
        <div className="container">
          <div className="deco-orange" />
          <div className="deco-yellow" />
          <div className="deco-blue" />
          <div className="deco-green" />

          <div className="distribution__inner">
            <div className="distribution__block">
              <h3>{getTranslatedText("Our Ratios")}</h3>

              <div className="distribution__data">
                <div className="bullet">{getTranslatedText("Product Development -")} <b>5%</b> </div>
                <div className="bullet">{getTranslatedText("Treasury Reserve -")} <b>65%</b> </div>
                <div className="bullet">{getTranslatedText("Exchange -")} <b>5%</b> </div>
                <div className="bullet">{getTranslatedText("Public Sale -")} <b>15%</b> </div>
                <div className="bullet">{getTranslatedText("Marketing -")} <b>10%</b> </div>
              </div>

              <img className="distribution__stars" src="./assets/images/distribution/mob-stars.svg" alt="decorator" />

            </div>
            <img className="distribution__bg" src="./assets/images/distribution/distribution-slices-parts.svg" alt="" />
            <div className="distribution__slices" id="svg1Image">
              <img src="./assets/images/distribution/distribution-slices.svg" alt="" />
            </div>
          </div>

          <div className="distribution__points">
            <div className="row">
              <div className="col-md-6">
                <div className="bullet bullet--lblue">
                  {getTranslatedText("Token Name: Alarmz Token")} (ALZ)
                </div>
                <div className="bullet bullet--dyellow">
                  {getTranslatedText("Initial Price:")} 10 ALZ {`>`} 1 USD
                </div>
                <div className="bullet bullet--yellow">
                  {getTranslatedText("Platform: Binance Smart Chain (BSC)")}
                </div>
              </div>
              <div className="col-md-6 text-lg-end">
                <div className="d-inline-block">
                  <div className="bullet bullet--dyellow">
                    {getTranslatedText("Token Utility: Governance, Access, Incentives, Value Exchange")}
                  </div>
                  <div className="bullet bullet--yellow">
                    {getTranslatedText("Token Standard: BEP-20")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="swapit" id='swapit'>
        <div className="deco-blue" />
        <div className="deco-yellow" />
        <div className="container">
          <div className="section-toptitle">
            <img src="./assets/images/swapit-toptitle.svg" alt="footer" />
          </div>
          <h2 className="section-title h2">
            {getTranslatedText("Swap IT")} <span className="color-orange">!</span>
          </h2>
          <p className="section-desc">
            {getTranslatedText("Experience seamless and secure token swapping on our platform with ALZ Token. Unlock effortless value exchange and enjoy a hassle-free trading experience.")}
          </p>
        </div>
        <div className="container">
          <SwapComponent />
          <div className="row" />
        </div>
      </section>

      <section className="milestone" id='roadmap'>
        <div className="deco-blue" />
        <div className="deco-yellow" />
        <div className="deco-orange" />
        <div className="container">
          <div className="section-toptitle">
            <img src="./assets/images/milestone-toptitle.svg" alt="footer" />
          </div>
          <h2 className="section-title h2">
            {getTranslatedText("Milestone")} <span className="color-liteblue">!</span>
          </h2>
          <p className="section-desc">
            {getTranslatedText("Navigate your path to success with our roadmap, providing clear direction and achievable goals at each stage.")}
          </p>
          <div className="milestone__inner">
            <div className="deco-triangle">
              <img src="./assets/images/milestone/triangle.png" alt="..." />
            </div>
            <div className="milestone__items">
              <div className="row">
                <div className="col-md-4">
                  <div className="milestone__single milestone__single--yellow">
                    <div className="milestone__img">
                      <img src="./assets/images/milestone/alarmz-token-launch.svg" alt="Alarmz Token Launch" />
                    </div>
                    <div className="button-wrapper">
                      <a className="button active" href="javascript:void(0)">
                        <span>{getTranslatedText("ALZ Token Launch")}</span>
                      </a>
                    </div>
                    <div className="bullet">
                      {getTranslatedText("Empowering ecosystem governance, access, incentives, and value exchange.")}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="milestone__single milestone__single--lblue">
                    <div className="milestone__img">
                      <img src="./assets/images/milestone/alarmz-dao-launch.svg" alt="Alarmz DAO Launch" />
                    </div>
                    <div className="button-wrapper">
                      <a className="button active" href="javascript:void(0)">
                        <span>{getTranslatedText("DAO Launch")}</span>
                      </a>
                    </div>
                    <div className="bullet">
                      {getTranslatedText("Fostering a collaborative and innovative culture for long-term protocol success.")}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="milestone__single milestone__single--green">
                    <div className="milestone__img">
                      <img src="./assets/images/milestone/alarmz-Staking.svg" alt="Alarmz staking" />
                    </div>
                    <div className="button-wrapper">
                      <a className="button active" href="javascript:void(0)">
                        <span>{getTranslatedText("Staking")}</span>
                      </a>
                    </div>
                    <div className="bullet">
                      {getTranslatedText("Enhancing network security and decentralization through 2-year token staking.")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="milestone__single milestone__single--lblue">
                    <div className="milestone__img">
                      <img src="./assets/images/milestone/alarmz-mainnet-launching.svg" alt="Alarmz Mainnet Launching" />
                    </div>
                    <div className="button-wrapper">
                      <a className="button active" href="javascript:void(0)">
                        <span>{getTranslatedText("Mainnet Launch")}</span>
                      </a>
                    </div>
                    <div className="bullet">
                      {getTranslatedText("Join Alarmz Main net for lightning-fast communication.")}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="milestone__single milestone__single--blue">
                    <div className="milestone__img">
                      <img src="./assets/images/milestone/alarmz-layer1-dag-blockchain-launch.svg" alt="Alarmz Mainnet Launching" />
                    </div>
                    <div className="button-wrapper">
                      <a className="button active" href="javascript:void(0)">
                        <span>{getTranslatedText("Layer1 DAG Blockchain Launch")}</span>
                      </a>
                    </div>
                    <div className="bullet">
                      {getTranslatedText("Unleashing efficient communication with the fastest TPS blockchain.")}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="milestone__single milestone__single--musterd">
                    <div className="milestone__img">
                      <img src="./assets/images/milestone/alarmz-app-launch.svg" alt="Alarmz Mainnet Launching" />
                    </div>
                    <div className="button-wrapper">
                      <a className="button active" href="javascript:void(0)">
                        <span>{getTranslatedText("Alarmz App Launch")}</span>
                      </a>
                    </div>
                    <div className="bullet">
                      {getTranslatedText("Set alarms and receive notifications for reminders, alerts, and emergencies.")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="justask" id='faq'>
        <div className="rounded-circle position-absolute gradient-orange" />
        <div className="rounded-circle position-absolute gradient-blue" />
        <div className="rounded-circle position-absolute gradient-blue-light" />
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="sticky-notes">
                <ul className="list-inline">
                  <li>
                    {/* <!-- <a
                      href="javascript:void(0)"
                      id="sticky-link--active"
                      className="sticky-link sticky-link--blue onload-prop sticky-link--active"
                    > --> */}
                    <a href="javascript:void(0)" className="sticky-link sticky-link--blue">
                      <span className="title">
                        {getTranslatedText("What is Alarmz?")}
                      </span>
                      <span className="desc">
                        {getTranslatedText("The groundbreaking Web 3 protocol revolutionizing the crypto industry, connecting apps, wallets, exchanges, and platforms for universal interoperability.")}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" className="sticky-link sticky-link--orange">
                      <span className="title">
                        {getTranslatedText("What is Alarmz trying to solve?")}
                      </span>
                      <span className="desc">
                        {getTranslatedText("Alarmz eliminates blockchain network barriers, enabling seamless communication between decentralized apps, wallets, and platforms, driving growth in the crypto industry.")}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" className="sticky-link sticky-link--yellow">
                      <span className="title">
                        {getTranslatedText("What is the utility ALZ Token?")}
                      </span>
                      <span className="desc">
                        {getTranslatedText("ALZ Token empowers users within the Alarmz ecosystem, enabling smooth transactions, incentivizing active participation, and unlocking premium features and services.")}
                      </span>
                    </a>
                  </li>
                  <li>
                    <div className="position-absolute arrow">
                      <img src="./assets/images/arrow-ask.svg" alt="" />
                    </div>
                  </li>
                  <li>
                    <div className="position-absolute spiral-left">
                      <img src="./assets/images/spiral-left.svg" alt="" />
                    </div>
                  </li>
                  <li>
                    <div className="position-absolute spiral-right">
                      <img src="./assets/images/spiral-right.svg" alt="" />
                    </div>
                  </li>
                  <li>
                    <a href="javascript:void(0)" className="sticky-link sticky-link--light-blue">
                      <span className="title">
                        {getTranslatedText("How can I utilize Alarmz?")}
                      </span>
                      <span className="desc">
                        {getTranslatedText("Leverage Alarmz to enhance crypto communication: securely send messages, receive notifications, and access cost-effective channels for an enhanced community experience.")}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" className="sticky-link sticky-link--green">
                      <span className="title">
                        {getTranslatedText("What products has Alarmz launched?")}
                      </span>
                      <span className="desc">
                        {getTranslatedText("Alarmz has introduced a range of innovative products including a Web 3 Protocol for unified communication, a decentralized governance DAO, a Layer 1 DAG Blockchain, and the Alarmz Mobile App.")}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="position-relative col-lg-6">
              <div className="section-toptitle">
                <img src="./assets/images/justask-toptitle.svg" alt="footer" />
              </div>
              <h2 className="section-title h2">
                {getTranslatedText("Just Ask")} <span className="color-yellow">!</span>
              </h2>
              <div className="blockquote">
                {getTranslatedText("Receive prompt and efficient answers to your inquiries from our expert team.")}
              </div>
              <div className="form-group form-group--withIconBtn">
                <input
                  value={faqQuestion}
                  type="text"
                  placeholder={getTranslatedText("Write down your question here!")}
                  className="form-control"
                  onChange={(e) => setFaqQuestion(e.target.value)}
                />
                <button className="btn p-0 border-0 rounded-circle btn--yellow-circular" onClick={justAsk}>
                  <img
                    src="./assets/images/icons/icon-search.svg"
                    alt="Search icon"
                  />
                </button>
              </div>
              <div className="d-lg-none">
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        {getTranslatedText("What is Alarmz?")}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {getTranslatedText("The groundbreaking Web 3 protocol revolutionizing the crypto industry, connecting apps, wallets, exchanges, and platforms for universal interoperability.")}
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        {getTranslatedText("What is Alarmz trying to solve?")}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {getTranslatedText("Alarmz eliminates blockchain network barriers, enabling seamless communication between decentralized apps, wallets, and platforms, driving growth in the crypto industry.")}
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        {getTranslatedText("What is the utility ALZ Token?")}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {getTranslatedText("ALZ Token empowers users within the Alarmz ecosystem, enabling smooth transactions, incentivizing active participation, and unlocking premium features and services.")}
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                      >
                        {getTranslatedText("How can I utilize Alarmz?")}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {getTranslatedText("Leverage Alarmz to enhance crypto communication: securely send messages, receive notifications, and access cost-effective channels for an enhanced community experience.")}
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFive"
                        aria-expanded="false"
                        aria-controls="flush-collapseFive"
                      >
                        {getTranslatedText("What products has Alarmz launched?")}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFive"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {getTranslatedText("Alarmz has introduced a range of innovative products including a Web 3 Protocol for unified communication, a decentralized governance DAO, a Layer 1 DAG Blockchain, and the Alarmz Mobile App.")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-end sqauresImg">
                <img src="./assets/images/just-ask-squares.svg" alt="Squares" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contactus" id='contactus'>
        <div className="position-relative container">
          <div className="section-toptitle">
            <img src="./assets/images/contact-toptitle.svg" alt="footer" />
          </div>
          <h2 className="section-title h2">
            {getTranslatedText("Contact Us")} <span className="color-orange">!</span>
          </h2>
          {/* Decorator */}
          <div className="yellow-circle" />
          <div className="blue-circle" />
          <div className="position-relative contactus__block">
            {/* Decorator */}
            <div className="position-absolute star">
              <img src="./assets/images/stars-contactUs.svg" alt="..." />
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="pb-lg-5 form-group">
                  <div className="label">
                    <div className="button button-blue active">
                      <span className="text-uppercase me-0">
                        {getTranslatedText("Asking for your name")}
                      </span>
                      <img
                        src="./assets/images/icons/icon-arrow-down.svg"
                        alt="..."
                      />
                    </div>
                  </div>
                  <input
                    value={nameForQuery}
                    type="text"
                    placeholder={getTranslatedText("What to call you?")}
                    className="form-control"
                    onChange={(e) => setNameForQuery(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <div className="label">
                    <div className="button button-green active">
                      <span className="text-uppercase me-0">
                        {getTranslatedText("We need your email address")}
                      </span>
                      <img
                        src="./assets/images/icons/icon-arrow-down.svg"
                        alt="..."
                      />
                    </div>
                  </div>
                  <input
                    value={mailForQuery}
                    type="text"
                    placeholder={getTranslatedText("How to contact you?")}
                    className="form-control"
                    onChange={(e) => setMailForQuery(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="label">
                    <div className="button button-blue active">
                      <span className="text-uppercase me-0">
                        {getTranslatedText("WRITE YOUR QUESTION HERE")}
                      </span>
                      <img
                        src="./assets/images/icons/icon-arrow-down.svg"
                        alt="..."
                      />
                    </div>
                  </div>
                  <textarea
                    value={descriptionForQuery}
                    placeholder={getTranslatedText("Describe us what you are looking for?")}
                    className="form-control"
                    defaultValue={""}
                    onChange={(e) => setDescriptionForQuery(e.target.value)}
                  />
                </div>
                <div className="pt-lg-2 text-center">
                  <button
                    type="submit"
                    className="button button-yellow"
                    onClick={submitQuery}
                    id='placeQuery'
                  >
                    {getTranslatedText("Place your Query!")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Decorator */}
          <div className="flower">
            <img src="./assets/images/flower-contactUs.svg" alt="..." />
          </div>
          <div className="ms-auto blue-square">
            <img src="./assets/images/deco-rect1.svg" alt="..." />
          </div>
        </div>
      </section>

      <footer className="footer">
        {/* Decorators */}
        <div className="position-absolute dots">
          <img src="./assets/images/dots-footer.svg" alt="" />
        </div>
        <div className="position-absolute dashed-circle">
          <img src="./assets/images/deco-circle2.svg" alt="" />
        </div>
        <div className="container">
          <div className="section-toptitle">
            <img src="./assets/images/footer-toptitle.svg" alt="footer" />
          </div>
          <div className="text-center footer__logo">
            <img src="./assets/images/Alarmz-logo.svg" alt="Alarmz logo" />
          </div>
          <p className="mx-auto text-center section-desc">
            {getTranslatedText("Web 3 Unified Communication Protocol enhancing Crypto Communications Channel")}
          </p>
          <div className="mx-auto form-group form-group--withIconBtn">
            <input
              value={subscribeEmail}
              type="text"
              placeholder={getTranslatedText("Subscribe to our newsletter!")}
              className="form-control"
              onChange={(e) => setSubscribeEmail(e.target.value)}
            />
            <button
              className="btn p-0 border-0 rounded-circle btn--yellow-circular"
              onClick={subscribeNews}
            >
              <img
                src="./assets/images/icons/icon-newsletter.svg"
                alt="Newsletter icon"
              />
            </button>
          </div>
          <ul className="mb-0 list-inline social">
            <li>
              <button
                type="button"
                className="rounded-circle p-0 ts03 btn social__btn"
                onClick={(e) => openSocialMediaLink(e, 'https://twitter.com/Alarmzprotocol')}
              >
                <img src="./assets/images/icons/icon-twitter.svg" alt="" />
              </button>
            </li>
            <li>
              <button
                type="button"
                className="rounded-circle p-0 ts03 btn social__btn"
                onClick={(e) => openSocialMediaLink(e, 'https://www.instagram.com/alarmzprotocol/')}
              >
                <img src="./assets/images/icons/icon-instagram.svg" alt="" />
              </button>
            </li>
            <li>
              <button
                type="button"
                className="rounded-circle p-0 ts03 btn social__btn"
                onClick={(e) => openSocialMediaLink(e, 'https://t.me/alarmzprotocol')}
              >
                <img src="./assets/images/icons/icon-telegram.svg" alt="" />
              </button>
            </li>
            <li>
              <button
                type="button"
                className="rounded-circle p-0 ts03 btn social__btn"
                onClick={(e) => openSocialMediaLink(e, 'https://discord.gg/T4GT8kMe')}
              >
                <img src="./assets/images/icons/icon-discord.svg" alt="" style={{ width: '25px' }} />
              </button>
            </li>
          </ul>
          <div className="mx-auto text-center section-desc ">{getTranslatedText("AlarmZ Inc. 701 Tillery Street Unit 12, #1588 Austin, Texas 78702 USA")}</div>
          <div className="mobile-changes">
            <ul className="mb-0 list-inline footer__menu" style={{ paddingBottom: '1.3rem' }}>
              <li>
                <a href="#platform" className="menu-link">
                  {" "}
                  {getTranslatedText("Platform")}{" "}
                </a>
              </li>
              <li>
                <a href="#benefits" className="menu-link">
                  {" "}
                  {getTranslatedText("Benefits")}{" "}
                </a>
              </li>
              <li>
                <a href="#swapit" className="menu-link">
                  {" "}
                  {getTranslatedText("Token Sale")}{" "}
                </a>
              </li>
              <li>
                <a href="#roadmap" className="menu-link">
                  {" "}
                  {getTranslatedText("Roadmap")}{" "}
                </a>
              </li>
              <li>
                <a href="#faq" className="menu-link">
                  {" "}
                  {getTranslatedText("FAQ")}{" "}
                </a>
              </li>
              <li>
                <a href="contactus" className="menu-link">
                  {" "}
                  {getTranslatedText("Contacts")}{" "}
                </a>
              </li>
            </ul>
            <ul className="mb-0 list-inline footer__menu">
              <li>
                <a href="./assets/pdf/privacy_policy.pdf" target='__blank' className="menu-link">
                  {" "}
                  {getTranslatedText("Privacy Policy")}{" "}
                </a>
              </li>
              <li>
                <a href="./assets/pdf/risk_disclosure_alarmz.pdf" target='__blank' className="menu-link">
                  {" "}
                  {getTranslatedText("Risk Disclaimer")}{" "}
                </a>
              </li>
              <li>
                <a href="./assets/pdf/terms&conditions.pdf" target='__blank' className="menu-link">
                  {" "}
                  {getTranslatedText("Terms of use")}{" "}
                </a>
              </li>
              <li>
                <a href="./assets/pdf/aml.pdf" target='__blank' className="menu-link">
                  {" "}
                  {getTranslatedText("KYC policy")}{" "}
                </a>
              </li>
            </ul>
            <div className="only-mobile">
              <div className="d-flex d-lg-none">
                <a className="button button-yellow find-btn" href="/assets/pdf/alarmz_pitchdeck.pdf" target='_blank'>
                  <span style={{ fontSize: '10px', width: '60px', padding: '5px' }}>{getTranslatedText("FIND US HERE")}</span>
                  {/* <img src="./assets/images/icons/icon-arrow-down.svg" alt="..." /> */}
                </a>
              </div>
              <div className="text-center mx-auto w-100 button-wrapper">
                <a className="button p-0 button-apple footer-apple" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#subscribeModal">
                  <img src="./assets/images/btn-apple.svg" alt="..." className='m-auto w-75' />
                </a>
                <a className="button p-0 button-apple footer-apple" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#subscribeModal">
                  <img src="./assets/images/btn-google.svg" alt="..." className='m-auto w-75' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* Subscribe news modal */}
      <div className="modal fade modal-style1 modal--thankyou" id="thankyouModalSubscribe" tabindex="-1"
        aria-labelledby="thankyouModalLabel" aria-hidden="true">
        <div className="app-decorators">
          <div className="rounded-circle position-absolute gradient-blue"></div>
          <div className="rounded-circle position-absolute gradient-yellow"></div>
          <div className="position-absolute deco-dots"><img src="./assets/images/layout-dots.svg" alt="" /></div>
          <div className="position-absolute deco-triangle"><img src="./assets/images/popup/triangle.png" alt="" /></div>
        </div>
        <div className="position-absolute deco-box"><img src="./assets/images/popup/box.svg" alt="" /></div>

        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div className="doco-squares">
                <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
              </div>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.3672 15.3091C19.3672 15.7356 19.2179 16.0981 18.9194 16.3966L16.7443 18.5717C16.4458 18.8703 16.0832 19.0195 15.6567 19.0195C15.2303 19.0195 14.8677 18.8703 14.5692 18.5717L9.86719 13.8697L5.16517 18.5717C4.86663 18.8703 4.50411 19.0195 4.07763 19.0195C3.65114 19.0195 3.28862 18.8703 2.99008 18.5717L0.814999 16.3966C0.516458 16.0981 0.367188 15.7356 0.367188 15.3091C0.367188 14.8826 0.516458 14.5201 0.814999 14.2216L5.51702 9.51953L0.814999 4.81751C0.516458 4.51897 0.367188 4.15646 0.367188 3.72997C0.367188 3.30348 0.516458 2.94097 0.814999 2.64243L2.99008 0.467343C3.28862 0.168802 3.65114 0.0195312 4.07763 0.0195312C4.50411 0.0195312 4.86663 0.168802 5.16517 0.467343L9.86719 5.16936L14.5692 0.467343C14.8677 0.168802 15.2303 0.0195312 15.6567 0.0195312C16.0832 0.0195312 16.4458 0.168802 16.7443 0.467343L18.9194 2.64243C19.2179 2.94097 19.3672 3.30348 19.3672 3.72997C19.3672 4.15646 19.2179 4.51897 18.9194 4.81751L14.2174 9.51953L18.9194 14.2216C19.2179 14.5201 19.3672 14.8826 19.3672 15.3091Z"
                    fill="black" />
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <div className="title-img">
                  <img src="./assets/images/thank-you-title.svg" alt="Thank You" />
                </div>
                <h2 className="h2">{getTranslatedText("Thank you for subscribing")}<span className="color-yellow">!</span></h2>
                <div className="text1">{getTranslatedText("Enjoy our exclusive updates and offers.")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button id='subscribeNews' data-bs-toggle="modal" data-bs-target="#thankyouModalSubscribe" style={{ display: 'none' }}></button>

      {/* Query news modal */}
      <div className="modal fade modal-style1 modal--thankyou" id="thankyouModalQuery" tabindex="-1"
        aria-labelledby="thankyouModalLabel" aria-hidden="true">
        <div className="app-decorators">
          <div className="rounded-circle position-absolute gradient-blue"></div>
          <div className="rounded-circle position-absolute gradient-yellow"></div>
          <div className="position-absolute deco-dots"><img src="./assets/images/layout-dots.svg" alt="" /></div>
          <div className="position-absolute deco-triangle"><img src="./assets/images/popup/triangle.png" alt="" /></div>
        </div>
        <div className="position-absolute deco-box"><img src="./assets/images/popup/box.svg" alt="" /></div>

        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div className="doco-squares">
                <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
              </div>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.3672 15.3091C19.3672 15.7356 19.2179 16.0981 18.9194 16.3966L16.7443 18.5717C16.4458 18.8703 16.0832 19.0195 15.6567 19.0195C15.2303 19.0195 14.8677 18.8703 14.5692 18.5717L9.86719 13.8697L5.16517 18.5717C4.86663 18.8703 4.50411 19.0195 4.07763 19.0195C3.65114 19.0195 3.28862 18.8703 2.99008 18.5717L0.814999 16.3966C0.516458 16.0981 0.367188 15.7356 0.367188 15.3091C0.367188 14.8826 0.516458 14.5201 0.814999 14.2216L5.51702 9.51953L0.814999 4.81751C0.516458 4.51897 0.367188 4.15646 0.367188 3.72997C0.367188 3.30348 0.516458 2.94097 0.814999 2.64243L2.99008 0.467343C3.28862 0.168802 3.65114 0.0195312 4.07763 0.0195312C4.50411 0.0195312 4.86663 0.168802 5.16517 0.467343L9.86719 5.16936L14.5692 0.467343C14.8677 0.168802 15.2303 0.0195312 15.6567 0.0195312C16.0832 0.0195312 16.4458 0.168802 16.7443 0.467343L18.9194 2.64243C19.2179 2.94097 19.3672 3.30348 19.3672 3.72997C19.3672 4.15646 19.2179 4.51897 18.9194 4.81751L14.2174 9.51953L18.9194 14.2216C19.2179 14.5201 19.3672 14.8826 19.3672 15.3091Z"
                    fill="black" />
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <div className="title-img">
                  <img src="./assets/images/thank-you-title.svg" alt="Thank You" />
                </div>
                <h2 className="h2">{getTranslatedText("We'll be in touch soon")}<span className="color-yellow">!</span></h2>
                <div className="text1">{getTranslatedText("Thank you for reaching out!")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button id='query' data-bs-toggle="modal" data-bs-target="#thankyouModalQuery" style={{ display: 'none' }}></button>

      {/* Justask news modal */}
      <div className="modal fade modal-style1 modal--thankyou" id="thankyouModalJust" tabindex="-1"
        aria-labelledby="thankyouModalLabel" aria-hidden="true">
        <div className="app-decorators">
          <div className="rounded-circle position-absolute gradient-blue"></div>
          <div className="rounded-circle position-absolute gradient-yellow"></div>
          <div className="position-absolute deco-dots"><img src="./assets/images/layout-dots.svg" alt="" /></div>
          <div className="position-absolute deco-triangle"><img src="./assets/images/popup/triangle.png" alt="" /></div>
        </div>
        <div className="position-absolute deco-box"><img src="./assets/images/popup/box.svg" alt="" /></div>

        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div className="doco-squares">
                <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
              </div>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.3672 15.3091C19.3672 15.7356 19.2179 16.0981 18.9194 16.3966L16.7443 18.5717C16.4458 18.8703 16.0832 19.0195 15.6567 19.0195C15.2303 19.0195 14.8677 18.8703 14.5692 18.5717L9.86719 13.8697L5.16517 18.5717C4.86663 18.8703 4.50411 19.0195 4.07763 19.0195C3.65114 19.0195 3.28862 18.8703 2.99008 18.5717L0.814999 16.3966C0.516458 16.0981 0.367188 15.7356 0.367188 15.3091C0.367188 14.8826 0.516458 14.5201 0.814999 14.2216L5.51702 9.51953L0.814999 4.81751C0.516458 4.51897 0.367188 4.15646 0.367188 3.72997C0.367188 3.30348 0.516458 2.94097 0.814999 2.64243L2.99008 0.467343C3.28862 0.168802 3.65114 0.0195312 4.07763 0.0195312C4.50411 0.0195312 4.86663 0.168802 5.16517 0.467343L9.86719 5.16936L14.5692 0.467343C14.8677 0.168802 15.2303 0.0195312 15.6567 0.0195312C16.0832 0.0195312 16.4458 0.168802 16.7443 0.467343L18.9194 2.64243C19.2179 2.94097 19.3672 3.30348 19.3672 3.72997C19.3672 4.15646 19.2179 4.51897 18.9194 4.81751L14.2174 9.51953L18.9194 14.2216C19.2179 14.5201 19.3672 14.8826 19.3672 15.3091Z"
                    fill="black" />
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <div className="title-img">
                  <img src="./assets/images/thank-you-title.svg" alt="Thank You" />
                </div>
                <h2 className="h2">{getTranslatedText("Thank you for your question")}<span className="color-yellow">!</span></h2>
                <div className="text1">{getTranslatedText("We'll provide a prompt response soon.")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button id='jusask' data-bs-toggle="modal" data-bs-target="#thankyouModalJust" style={{ display: 'none' }}></button>

      {/* Joinwaitlist waitlist modal */}
      <div className="modal fade modal-style1 modal--thankyou modal--subscribe" id="subscribeModal" tabindex="-1"
        aria-labelledby="thankyouModalLabel" aria-hidden="true">
        <div className="app-decorators">
          <div className="rounded-circle position-absolute gradient-blue"></div>
          <div className="rounded-circle position-absolute gradient-yellow"></div>
          <div className="position-absolute deco-dots"><img src="./assets/images/layout-dots.svg" alt="" /></div>
          <div className="position-absolute deco-triangle"><img src="./assets/images/popup/triangle.png" alt="" /></div>
        </div>
        <div className="position-absolute deco-box"><img src="./assets/images/popup/box.svg" alt="" /></div>

        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ height: '450px' }}>
            <div className="modal-header">
              <div className="doco-squares">
                <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
              </div>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.3672 15.3091C19.3672 15.7356 19.2179 16.0981 18.9194 16.3966L16.7443 18.5717C16.4458 18.8703 16.0832 19.0195 15.6567 19.0195C15.2303 19.0195 14.8677 18.8703 14.5692 18.5717L9.86719 13.8697L5.16517 18.5717C4.86663 18.8703 4.50411 19.0195 4.07763 19.0195C3.65114 19.0195 3.28862 18.8703 2.99008 18.5717L0.814999 16.3966C0.516458 16.0981 0.367188 15.7356 0.367188 15.3091C0.367188 14.8826 0.516458 14.5201 0.814999 14.2216L5.51702 9.51953L0.814999 4.81751C0.516458 4.51897 0.367188 4.15646 0.367188 3.72997C0.367188 3.30348 0.516458 2.94097 0.814999 2.64243L2.99008 0.467343C3.28862 0.168802 3.65114 0.0195312 4.07763 0.0195312C4.50411 0.0195312 4.86663 0.168802 5.16517 0.467343L9.86719 5.16936L14.5692 0.467343C14.8677 0.168802 15.2303 0.0195312 15.6567 0.0195312C16.0832 0.0195312 16.4458 0.168802 16.7443 0.467343L18.9194 2.64243C19.2179 2.94097 19.3672 3.30348 19.3672 3.72997C19.3672 4.15646 19.2179 4.51897 18.9194 4.81751L14.2174 9.51953L18.9194 14.2216C19.2179 14.5201 19.3672 14.8826 19.3672 15.3091Z"
                    fill="black" />
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <div className="title-img">
                  <img src="./assets/images/thank-you-title.svg" alt="Thank You" />
                </div>
                <h2 className="h2">{getTranslatedText("Be the first to know")}<span className="color-yellow">!</span></h2>
                <div className="text1">{getTranslatedText("Join our App launch waitlist today")}</div>
                <form>
                  <div className="mx-auto form-group form-group--withIconBtn">
                    <input
                      value={joinWaitListEmail}
                      required
                      type="text"
                      placeholder={getTranslatedText("Enter your mail id")}
                      className="form-control"
                      onChange={(e) => setJoinWaitListEmail(e.target.value)}
                    />
                    <button
                      className="btn p-0 border-0 rounded-circle btn--yellow-circular"
                      onClick={joinWaitList}
                    >
                      <img src="./assets/images/icons/icon-newsletter.svg" alt="Newsletter icon" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Thank you modal after clicking joinwait list modal */}
      <div className="modal fade modal-style1 modal--thankyou" id="thankyouModalJoinwaitlist" tabindex="-1"
        aria-labelledby="thankyouModalLabel" aria-hidden="true">
        <div className="app-decorators">
          <div className="rounded-circle position-absolute gradient-blue"></div>
          <div className="rounded-circle position-absolute gradient-yellow"></div>
          <div className="position-absolute deco-dots"><img src="./assets/images/layout-dots.svg" alt="" /></div>
          <div className="position-absolute deco-triangle"><img src="./assets/images/popup/triangle.png" alt="" /></div>
        </div>
        <div className="position-absolute deco-box"><img src="./assets/images/popup/box.svg" alt="" /></div>

        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div className="doco-squares">
                <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
              </div>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.3672 15.3091C19.3672 15.7356 19.2179 16.0981 18.9194 16.3966L16.7443 18.5717C16.4458 18.8703 16.0832 19.0195 15.6567 19.0195C15.2303 19.0195 14.8677 18.8703 14.5692 18.5717L9.86719 13.8697L5.16517 18.5717C4.86663 18.8703 4.50411 19.0195 4.07763 19.0195C3.65114 19.0195 3.28862 18.8703 2.99008 18.5717L0.814999 16.3966C0.516458 16.0981 0.367188 15.7356 0.367188 15.3091C0.367188 14.8826 0.516458 14.5201 0.814999 14.2216L5.51702 9.51953L0.814999 4.81751C0.516458 4.51897 0.367188 4.15646 0.367188 3.72997C0.367188 3.30348 0.516458 2.94097 0.814999 2.64243L2.99008 0.467343C3.28862 0.168802 3.65114 0.0195312 4.07763 0.0195312C4.50411 0.0195312 4.86663 0.168802 5.16517 0.467343L9.86719 5.16936L14.5692 0.467343C14.8677 0.168802 15.2303 0.0195312 15.6567 0.0195312C16.0832 0.0195312 16.4458 0.168802 16.7443 0.467343L18.9194 2.64243C19.2179 2.94097 19.3672 3.30348 19.3672 3.72997C19.3672 4.15646 19.2179 4.51897 18.9194 4.81751L14.2174 9.51953L18.9194 14.2216C19.2179 14.5201 19.3672 14.8826 19.3672 15.3091Z"
                    fill="black" />
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <div className="title-img">
                  <img src="./assets/images/thank-you-title.svg" alt="Thank You" />
                </div>
                <h2 className="h2">{getTranslatedText("Thank You")}<span className="color-yellow">!</span></h2>
                <div className="text1">{getTranslatedText("We will reach out to you on app launch!")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button id='joinwaitlist' data-bs-toggle="modal" data-bs-target="#thankyouModalJoinwaitlist" style={{ display: 'none' }}></button>

      {/* Thank you token purchase modal */}
      <div className="modal fade modal-style1 modal--thankyou" id="thankyouModalPurchase" tabindex="-1"
        aria-labelledby="thankyouModalLabel" aria-hidden="true">
        <div className="app-decorators">
          <div className="rounded-circle position-absolute gradient-blue"></div>
          <div className="rounded-circle position-absolute gradient-yellow"></div>
          <div className="position-absolute deco-dots"><img src="./assets/images/layout-dots.svg" alt="" /></div>
          <div className="position-absolute deco-triangle"><img src="./assets/images/popup/triangle.png" alt="" /></div>
        </div>
        <div className="position-absolute deco-box"><img src="./assets/images/popup/box.svg" alt="" /></div>

        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div className="doco-squares">
                <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
              </div>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.3672 15.3091C19.3672 15.7356 19.2179 16.0981 18.9194 16.3966L16.7443 18.5717C16.4458 18.8703 16.0832 19.0195 15.6567 19.0195C15.2303 19.0195 14.8677 18.8703 14.5692 18.5717L9.86719 13.8697L5.16517 18.5717C4.86663 18.8703 4.50411 19.0195 4.07763 19.0195C3.65114 19.0195 3.28862 18.8703 2.99008 18.5717L0.814999 16.3966C0.516458 16.0981 0.367188 15.7356 0.367188 15.3091C0.367188 14.8826 0.516458 14.5201 0.814999 14.2216L5.51702 9.51953L0.814999 4.81751C0.516458 4.51897 0.367188 4.15646 0.367188 3.72997C0.367188 3.30348 0.516458 2.94097 0.814999 2.64243L2.99008 0.467343C3.28862 0.168802 3.65114 0.0195312 4.07763 0.0195312C4.50411 0.0195312 4.86663 0.168802 5.16517 0.467343L9.86719 5.16936L14.5692 0.467343C14.8677 0.168802 15.2303 0.0195312 15.6567 0.0195312C16.0832 0.0195312 16.4458 0.168802 16.7443 0.467343L18.9194 2.64243C19.2179 2.94097 19.3672 3.30348 19.3672 3.72997C19.3672 4.15646 19.2179 4.51897 18.9194 4.81751L14.2174 9.51953L18.9194 14.2216C19.2179 14.5201 19.3672 14.8826 19.3672 15.3091Z"
                    fill="black" />
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <div className="title-img">
                  <img src="./assets/images/thank-you-title.svg" alt="Thank You" />
                </div>
                <h2 className="h2">{getTranslatedText("Thank you for joining us")}<span className="color-yellow">!</span></h2>
                <div className="text1">{getTranslatedText("Enjoy exclusive benefits and rewards.")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button id='thankyoupurchase' data-bs-toggle="modal" data-bs-target="#thankyouModalPurchase" style={{ display: 'none' }}></button>

      {/* Disclaimer modal */}
      <div className="modal fade modal-style1 modal--thankyou" id="thankyouModalDisclaimer" tabindex="-1"
        aria-labelledby="thankyouModalLabel" aria-hidden="true">
        <div className="app-decorators">
          <div className="rounded-circle position-absolute gradient-blue"></div>
          <div className="rounded-circle position-absolute gradient-yellow"></div>
          <div className="position-absolute deco-dots"><img src="./assets/images/layout-dots.svg" alt="" /></div>
          <div className="position-absolute deco-triangle"><img src="./assets/images/popup/triangle.png" alt="" /></div>
        </div>
        <div className="position-absolute deco-box"><img src="./assets/images/popup/box.svg" alt="" /></div>

        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div className="doco-squares">
                <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
              </div>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.3672 15.3091C19.3672 15.7356 19.2179 16.0981 18.9194 16.3966L16.7443 18.5717C16.4458 18.8703 16.0832 19.0195 15.6567 19.0195C15.2303 19.0195 14.8677 18.8703 14.5692 18.5717L9.86719 13.8697L5.16517 18.5717C4.86663 18.8703 4.50411 19.0195 4.07763 19.0195C3.65114 19.0195 3.28862 18.8703 2.99008 18.5717L0.814999 16.3966C0.516458 16.0981 0.367188 15.7356 0.367188 15.3091C0.367188 14.8826 0.516458 14.5201 0.814999 14.2216L5.51702 9.51953L0.814999 4.81751C0.516458 4.51897 0.367188 4.15646 0.367188 3.72997C0.367188 3.30348 0.516458 2.94097 0.814999 2.64243L2.99008 0.467343C3.28862 0.168802 3.65114 0.0195312 4.07763 0.0195312C4.50411 0.0195312 4.86663 0.168802 5.16517 0.467343L9.86719 5.16936L14.5692 0.467343C14.8677 0.168802 15.2303 0.0195312 15.6567 0.0195312C16.0832 0.0195312 16.4458 0.168802 16.7443 0.467343L18.9194 2.64243C19.2179 2.94097 19.3672 3.30348 19.3672 3.72997C19.3672 4.15646 19.2179 4.51897 18.9194 4.81751L14.2174 9.51953L18.9194 14.2216C19.2179 14.5201 19.3672 14.8826 19.3672 15.3091Z"
                    fill="black" />
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <div className="title-img">
                  <img src="./assets/images/thank-you-title.svg" alt="Thank You" />
                </div>
                <h2 className="h2">{getTranslatedText("Important")}<span className="color-yellow">!</span></h2>
                <div className="text1">{getTranslatedText("We deeply respect your choices and seek your consent to familiarize yourself with the Alarmz disclaimer, as it outlines crucial terms and conditions for usage.")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button id='desclaimer' data-bs-toggle="modal" data-bs-target="#thankyouModalDisclaimer" style={{ display: 'none' }}></button>

      {/* Sale start modal */}
      <div className="modal fade modal-style1 modal--thankyou" id="salestart" tabindex="-1"
        aria-labelledby="thankyouModalLabel" aria-hidden="true">
        <div className="app-decorators">
          <div className="rounded-circle position-absolute gradient-blue"></div>
          <div className="rounded-circle position-absolute gradient-yellow"></div>
          <div className="position-absolute deco-dots"><img src="./assets/images/layout-dots.svg" alt="" /></div>
          <div className="position-absolute deco-triangle"><img src="./assets/images/popup/triangle.png" alt="" /></div>
        </div>
        <div className="position-absolute deco-box"><img src="./assets/images/popup/box.svg" alt="" /></div>

        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content h-100">
            <div className="modal-header">
              <div className="doco-squares">
                <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
              </div>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.3672 15.3091C19.3672 15.7356 19.2179 16.0981 18.9194 16.3966L16.7443 18.5717C16.4458 18.8703 16.0832 19.0195 15.6567 19.0195C15.2303 19.0195 14.8677 18.8703 14.5692 18.5717L9.86719 13.8697L5.16517 18.5717C4.86663 18.8703 4.50411 19.0195 4.07763 19.0195C3.65114 19.0195 3.28862 18.8703 2.99008 18.5717L0.814999 16.3966C0.516458 16.0981 0.367188 15.7356 0.367188 15.3091C0.367188 14.8826 0.516458 14.5201 0.814999 14.2216L5.51702 9.51953L0.814999 4.81751C0.516458 4.51897 0.367188 4.15646 0.367188 3.72997C0.367188 3.30348 0.516458 2.94097 0.814999 2.64243L2.99008 0.467343C3.28862 0.168802 3.65114 0.0195312 4.07763 0.0195312C4.50411 0.0195312 4.86663 0.168802 5.16517 0.467343L9.86719 5.16936L14.5692 0.467343C14.8677 0.168802 15.2303 0.0195312 15.6567 0.0195312C16.0832 0.0195312 16.4458 0.168802 16.7443 0.467343L18.9194 2.64243C19.2179 2.94097 19.3672 3.30348 19.3672 3.72997C19.3672 4.15646 19.2179 4.51897 18.9194 4.81751L14.2174 9.51953L18.9194 14.2216C19.2179 14.5201 19.3672 14.8826 19.3672 15.3091Z"
                    fill="black" />
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <div className="title-img">
                  <img src="./assets/images/thank-you-title.svg" alt="Thank You" />
                </div>
                <h2 className="h2">Join the ALZ token sale party<span className="color-yellow">!</span></h2>
                <div className="text1 mb-0">Ring the bells and make your portfolio sing with ALZ token sale !</div>
                <div className="text1 mb-4">We are going Live on</div>
                <div className=" mb-1 txt">Date: 28th June 2023</div>
                <div className=" mb-4 txt">Time: 6:30 AM EST (GMT-5)</div>
                {/* <div className="text1 mb-0">Don't miss out !</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <button id='sale' data-bs-toggle="modal" data-bs-target="#salestart" style={{ display: 'none' }}></button>

      {/* Referral modal */}
      <div className="modal fade modal-style1 modal--thankyou modal--subscribe" id="referralModal" tabindex="-1"
        aria-labelledby="thankyouModalLabel" aria-hidden="true">
        <div className="app-decorators">
          <div className="rounded-circle position-absolute gradient-blue"></div>
          <div className="rounded-circle position-absolute gradient-yellow"></div>
          <div className="position-absolute deco-dots"><img src="./assets/images/layout-dots.svg" alt="" /></div>
          <div className="position-absolute deco-triangle"><img src="./assets/images/popup/triangle.png" alt="" /></div>
        </div>
        <div className="position-absolute deco-box"><img src="./assets/images/popup/box.svg" alt="" /></div>

        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ height: '450px' }}>
            <div className="modal-header">
              <div className="doco-squares">
                <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
              </div>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.3672 15.3091C19.3672 15.7356 19.2179 16.0981 18.9194 16.3966L16.7443 18.5717C16.4458 18.8703 16.0832 19.0195 15.6567 19.0195C15.2303 19.0195 14.8677 18.8703 14.5692 18.5717L9.86719 13.8697L5.16517 18.5717C4.86663 18.8703 4.50411 19.0195 4.07763 19.0195C3.65114 19.0195 3.28862 18.8703 2.99008 18.5717L0.814999 16.3966C0.516458 16.0981 0.367188 15.7356 0.367188 15.3091C0.367188 14.8826 0.516458 14.5201 0.814999 14.2216L5.51702 9.51953L0.814999 4.81751C0.516458 4.51897 0.367188 4.15646 0.367188 3.72997C0.367188 3.30348 0.516458 2.94097 0.814999 2.64243L2.99008 0.467343C3.28862 0.168802 3.65114 0.0195312 4.07763 0.0195312C4.50411 0.0195312 4.86663 0.168802 5.16517 0.467343L9.86719 5.16936L14.5692 0.467343C14.8677 0.168802 15.2303 0.0195312 15.6567 0.0195312C16.0832 0.0195312 16.4458 0.168802 16.7443 0.467343L18.9194 2.64243C19.2179 2.94097 19.3672 3.30348 19.3672 3.72997C19.3672 4.15646 19.2179 4.51897 18.9194 4.81751L14.2174 9.51953L18.9194 14.2216C19.2179 14.5201 19.3672 14.8826 19.3672 15.3091Z"
                    fill="black" />
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <div className="title-img">
                  <img src="./assets/images/thank-you-title.svg" alt="Thank You" />
                </div>
                <h2 className="h2 mb-5">{getTranslatedText("Enter your Referral Id")}<span className="color-yellow">!</span></h2>
                {/* <div className="text1">Join our App launch waitlist today</div> */}
                {/* <form> */}
                <div className="mx-auto form-group form-group--withIconBtn">
                  <input
                    value={localReferralId}
                    required
                    type="text"
                    placeholder={getTranslatedText("Enter your Referral Id")}
                    className="form-control"
                    onChange={(e) => dispatch(setLocalReferralId(e.target.value))}
                  />
                  <button
                    className="btn p-0 border-0 rounded-circle btn--yellow-circular"
                    onClick={() => dispatch(setLocalReferralId(localReferralId))}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <img src="./assets/images/icons/icon-newsletter.svg" alt="Newsletter icon" />
                  </button>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <button id='referral' data-bs-toggle="modal" data-bs-target="#referralModal" style={{ display: 'none' }}></button>

      {/* Same Id modal */}
      <div className="modal fade modal-style1 modal--thankyou modal--subscribe" id="sameIdmodal" tabindex="-1"
        aria-labelledby="thankyouModalLabel" aria-hidden="true">
        <div className="app-decorators">
          <div className="rounded-circle position-absolute gradient-blue"></div>
          <div className="rounded-circle position-absolute gradient-yellow"></div>
          <div className="position-absolute deco-dots"><img src="./assets/images/layout-dots.svg" alt="" /></div>
          <div className="position-absolute deco-triangle"><img src="./assets/images/popup/triangle.png" alt="" /></div>
        </div>
        <div className="position-absolute deco-box"><img src="./assets/images/popup/box.svg" alt="" /></div>

        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ height: '450px' }}>
            <div className="modal-header">
              <div className="doco-squares">
                <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
              </div>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.3672 15.3091C19.3672 15.7356 19.2179 16.0981 18.9194 16.3966L16.7443 18.5717C16.4458 18.8703 16.0832 19.0195 15.6567 19.0195C15.2303 19.0195 14.8677 18.8703 14.5692 18.5717L9.86719 13.8697L5.16517 18.5717C4.86663 18.8703 4.50411 19.0195 4.07763 19.0195C3.65114 19.0195 3.28862 18.8703 2.99008 18.5717L0.814999 16.3966C0.516458 16.0981 0.367188 15.7356 0.367188 15.3091C0.367188 14.8826 0.516458 14.5201 0.814999 14.2216L5.51702 9.51953L0.814999 4.81751C0.516458 4.51897 0.367188 4.15646 0.367188 3.72997C0.367188 3.30348 0.516458 2.94097 0.814999 2.64243L2.99008 0.467343C3.28862 0.168802 3.65114 0.0195312 4.07763 0.0195312C4.50411 0.0195312 4.86663 0.168802 5.16517 0.467343L9.86719 5.16936L14.5692 0.467343C14.8677 0.168802 15.2303 0.0195312 15.6567 0.0195312C16.0832 0.0195312 16.4458 0.168802 16.7443 0.467343L18.9194 2.64243C19.2179 2.94097 19.3672 3.30348 19.3672 3.72997C19.3672 4.15646 19.2179 4.51897 18.9194 4.81751L14.2174 9.51953L18.9194 14.2216C19.2179 14.5201 19.3672 14.8826 19.3672 15.3091Z"
                    fill="black" />
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <div className="title-img">
                  <img src="./assets/images/thank-you-title.svg" alt="Thank You" />
                </div>
                <h2 className="h2 mb-5">{getTranslatedText("Something went wrong")} <span className="color-yellow">!</span></h2>
                <div className="text1">{getTranslatedText("Purchase Id and Refferal Id can not be same")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button id='sameId' data-bs-toggle="modal" data-bs-target="#sameIdmodal" style={{ display: 'none' }}></button>

      {/* Random error modal */}
      <div className="modal fade modal-style1 modal--thankyou modal--subscribe" id="randomerrormodal" tabindex="-1"
        aria-labelledby="thankyouModalLabel" aria-hidden="true">
        <div className="app-decorators">
          <div className="rounded-circle position-absolute gradient-blue"></div>
          <div className="rounded-circle position-absolute gradient-yellow"></div>
          <div className="position-absolute deco-dots"><img src="./assets/images/layout-dots.svg" alt="" /></div>
          <div className="position-absolute deco-triangle"><img src="./assets/images/popup/triangle.png" alt="" /></div>
        </div>
        <div className="position-absolute deco-box"><img src="./assets/images/popup/box.svg" alt="" /></div>

        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ height: '450px' }}>
            <div className="modal-header">
              <div className="doco-squares">
                <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
              </div>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.3672 15.3091C19.3672 15.7356 19.2179 16.0981 18.9194 16.3966L16.7443 18.5717C16.4458 18.8703 16.0832 19.0195 15.6567 19.0195C15.2303 19.0195 14.8677 18.8703 14.5692 18.5717L9.86719 13.8697L5.16517 18.5717C4.86663 18.8703 4.50411 19.0195 4.07763 19.0195C3.65114 19.0195 3.28862 18.8703 2.99008 18.5717L0.814999 16.3966C0.516458 16.0981 0.367188 15.7356 0.367188 15.3091C0.367188 14.8826 0.516458 14.5201 0.814999 14.2216L5.51702 9.51953L0.814999 4.81751C0.516458 4.51897 0.367188 4.15646 0.367188 3.72997C0.367188 3.30348 0.516458 2.94097 0.814999 2.64243L2.99008 0.467343C3.28862 0.168802 3.65114 0.0195312 4.07763 0.0195312C4.50411 0.0195312 4.86663 0.168802 5.16517 0.467343L9.86719 5.16936L14.5692 0.467343C14.8677 0.168802 15.2303 0.0195312 15.6567 0.0195312C16.0832 0.0195312 16.4458 0.168802 16.7443 0.467343L18.9194 2.64243C19.2179 2.94097 19.3672 3.30348 19.3672 3.72997C19.3672 4.15646 19.2179 4.51897 18.9194 4.81751L14.2174 9.51953L18.9194 14.2216C19.2179 14.5201 19.3672 14.8826 19.3672 15.3091Z"
                    fill="black" />
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <div className="title-img">
                  <img src="./assets/images/thank-you-title.svg" alt="Thank You" />
                </div>
                <h2 className="h2 mb-5">{getTranslatedText("Something went wrong")} <span className="color-yellow">!</span></h2>
                <div className="text1">{error1}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button id='randomError' data-bs-toggle="modal" data-bs-target="#randomerrormodal" style={{ display: 'none' }}></button>

      {/* Sponsor not available modal */}
      <div className="modal fade modal-style1 modal--thankyou modal--subscribe" id="sponsornotavailablemodal" tabindex="-1"
        aria-labelledby="thankyouModalLabel" aria-hidden="true">
        <div className="app-decorators">
          <div className="rounded-circle position-absolute gradient-blue"></div>
          <div className="rounded-circle position-absolute gradient-yellow"></div>
          <div className="position-absolute deco-dots"><img src="./assets/images/layout-dots.svg" alt="" /></div>
          <div className="position-absolute deco-triangle"><img src="./assets/images/popup/triangle.png" alt="" /></div>
        </div>
        <div className="position-absolute deco-box"><img src="./assets/images/popup/box.svg" alt="" /></div>

        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ height: '450px' }}>
            <div className="modal-header">
              <div className="doco-squares">
                <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
              </div>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.3672 15.3091C19.3672 15.7356 19.2179 16.0981 18.9194 16.3966L16.7443 18.5717C16.4458 18.8703 16.0832 19.0195 15.6567 19.0195C15.2303 19.0195 14.8677 18.8703 14.5692 18.5717L9.86719 13.8697L5.16517 18.5717C4.86663 18.8703 4.50411 19.0195 4.07763 19.0195C3.65114 19.0195 3.28862 18.8703 2.99008 18.5717L0.814999 16.3966C0.516458 16.0981 0.367188 15.7356 0.367188 15.3091C0.367188 14.8826 0.516458 14.5201 0.814999 14.2216L5.51702 9.51953L0.814999 4.81751C0.516458 4.51897 0.367188 4.15646 0.367188 3.72997C0.367188 3.30348 0.516458 2.94097 0.814999 2.64243L2.99008 0.467343C3.28862 0.168802 3.65114 0.0195312 4.07763 0.0195312C4.50411 0.0195312 4.86663 0.168802 5.16517 0.467343L9.86719 5.16936L14.5692 0.467343C14.8677 0.168802 15.2303 0.0195312 15.6567 0.0195312C16.0832 0.0195312 16.4458 0.168802 16.7443 0.467343L18.9194 2.64243C19.2179 2.94097 19.3672 3.30348 19.3672 3.72997C19.3672 4.15646 19.2179 4.51897 18.9194 4.81751L14.2174 9.51953L18.9194 14.2216C19.2179 14.5201 19.3672 14.8826 19.3672 15.3091Z"
                    fill="black" />
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <div className="title-img">
                  <img src="./assets/images/thank-you-title.svg" alt="Thank You" />
                </div>
                <h2 className="h2 mb-5">{getTranslatedText("Something went wrong")} <span className="color-yellow">!</span></h2>
                <div className="text1 mb-0">{getTranslatedText("Sponsor not available.")}</div>
                <div className="text1">{getTranslatedText("Please enter correct sponsor Id.")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button id='sponsorNotAvailable' data-bs-toggle="modal" data-bs-target="#sponsornotavailablemodal" style={{ display: 'none' }}></button>

      <div id="video-popup-overlay" />

      <div id="video-popup-container">
        <div id="video-popup-close" class="fade">&#10006;</div>
        <div id="video-popup-iframe-container">
          <iframe id="video-popup-iframe" src="" width="100%" height="100%" frameborder="0"></iframe>
        </div>
      </div>

      <Cookies1 />
    </body >
  )
}