import { useEffect, useState } from 'react';
import { APP_DATA } from '../utils';
import { ethers } from 'ethers';
import { useNavigate } from 'react-router-dom';

const ModelViewer = require('@metamask/logo');

function ConnectApp() {
  const navigate = useNavigate();

  useEffect(() => {
    if (document.getElementById('logo-container').innerHTML !== "") return;
    const viewer = ModelViewer({
      // Dictates whether width & height are px or multiplied
      pxNotRatio: true,
      width: 500,
      height: 400,
      // pxNotRatio: false,
      // width: 0.9,
      // height: 0.9,

      // To make the face follow the mouse.
      followMouse: false,

      // head should slowly drift (overrides lookAt)
      slowDrift: false,
    });
    // Body Loaded
    document.querySelector("body").classList.add("loaded");
    const container = document.getElementById('logo-container');
    container.appendChild(viewer.container);
    // look at something on the page
    viewer.lookAt({
      x: 100,
      y: 100,
    });

    // enable mouse follow
    viewer.setFollowMouse(true);

    return () => {
      viewer.stopAnimation();
    }
  }, []);

  const connectWallet = async () => {
    if (window.ethereum) {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const address = await signer.getAddress();
      const smart_contract_main = new ethers.Contract(APP_DATA.SC_ADDRESS, APP_DATA.SC_ABI, signer);
      const res = await smart_contract_main.doesUserExist(address);
      if (res) {
        navigate('/swap');
      } else {
        navigate('/register');
      }
    } else {
      alert('Install any wallet extension!')
    }
  };

  const logoClick = () => {
    navigate('/');
  };

  return (
    <div className="app-layout">
      <div className="app-decorators">
        {/* Decorators */}
        <div className="position-absolute deco-square"><img src="./assets/images/layout-blue-square.svg" alt="" /></div>
        <div className="rounded-circle position-absolute gradient-blue" />
        <div className="rounded-circle position-absolute gradient-yellow" />
        <div className="position-absolute deco-dots"><img src="./assets/images/layout-dots.svg" alt="" /></div>
      </div>
      <main className="app-content">
        <div className="app-header  app-header--loggedout">
          <div className="container">
            <section className="app-header__inner">
              <div className="logo" onClick={logoClick} style={{ cursor: 'pointer' }}>
                <img src="./assets/images/Alarmz-logo.svg" alt="Alarmz logo" />
              </div>
            </section>
          </div>
        </div>
        <div className="container">
          <section className="layout__content">
            <div className="illustration illustration--wallet">
              <img src="./assets/images/connect-wallet-illustration.svg" alt="" />
            </div>
            <div className="position-relative block-details">
              {/* Decorator */}
              <div className="position-absolute star">
                <img src="./assets/images/stars-contactUs.svg" alt="..." />
              </div>
              <div className="d-inline-block position-absolute flower">
                <img src="./assets/images/flower-contactUs.svg" alt="..." />
              </div>
              <div className="bg-white block-details__inner">
                <img src="./assets/images/connect-wallet-semi-round.svg" alt="Connect Your Wallet" className="semi-circle-title" />
                <h3 className="section-title h3">
                  Connect IT<span className="color-yellow">!</span>
                </h3>
                <div className="logo-gradient">
                  <div id="logo-container" />
                </div>
                <p className="text-center text-subtitle">
                  Lorem ipsum dolor sit amet consectetur. Id euismod quam diam
                  molestie.
                </p>
                <a className="button button-yellow launch-btn" role='button' onClick={connectWallet}>
                  {/* <img
                    src="./assets/images/animating/rocket/rocket.svg"
                    alt="launch"
                  /> */}
                  <span className="rounded-circle bg-white icon">
                    <img src="./assets/images/icons/rocket.svg" alt="" />
                  </span>
                  <span className="text-center text"> Connect Your Wallet </span>
                </a>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
}

export default ConnectApp;